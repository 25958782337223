import { getToken } from '../../hooks/useToken';
import {
  deleteContentObject,
  getContentObject,
  getContentObjects,
  getContentObjectVersion,
  getContentObjectVersions,
  getContentType,
  listContentTypes,
  patchContentObject,
  postContentObject,
  putContentObject,
  putContentType,
} from '../flotiq-client';
import { RolePermissions } from '../rolePermissions';
import { getMediaUrl } from '../flotiq-client/api-helpers';

class FlotiqScopedApiClient {
  #permissions;
  #emptyPermissions;
  #queryClient;

  constructor(permissions, queryClient) {
    this.#permissions = new RolePermissions(permissions);
    this.#emptyPermissions = !permissions || !permissions.length;
    this.#queryClient = queryClient;
  }

  #clearContentObjectsCache = (type, id = null) =>
    this.#queryClient.invalidateQueries({
      queryKey: ['content-objects'],
      predicate: (query) =>
        query.queryKey[query.queryKey.length - 2]?.contentTypeName === type &&
        query.queryKey[query.queryKey.length - 1] === 'list' &&
        (!id || query.queryKey[query.queryKey.length - 2]?.id === id),
    });

  #clearContentTypesCache = (type) => {
    this.#queryClient.invalidateQueries({
      queryKey: ['content-types'],
      predicate: (query) =>
        query.queryKey[query.queryKey.length - 1] === 'list' ||
        query.queryKey[query.queryKey.length - 2]?.contentTypeName === type,
    });
  };

  getObject(type, id, _public = false) {
    if (this.#emptyPermissions || !this.#permissions.canCo(type))
      throw new Error(
        `Plugin does not have permission to read ${type} objects`,
      );

    return getContentObject(getToken(), undefined, {
      contentTypeName: type,
      id,
      public: _public,
    });
  }

  getObjectVersions(type, id) {
    if (this.#emptyPermissions || !this.#permissions.canCo(type))
      throw new Error(
        `Plugin does not have permission to read ${type} objects`,
      );

    return getContentObjectVersions(getToken(), undefined, {
      contentTypeName: type,
      id,
    });
  }

  getObjectVersion(type, id, version) {
    if (this.#emptyPermissions || !this.#permissions.canCo(type))
      throw new Error(
        `Plugin does not have permission to read ${type} objects`,
      );

    return getContentObjectVersion(getToken(), undefined, {
      contentTypeName: type,
      id,
      versionNumber: version,
    });
  }

  listObjects(type, query, _public = false) {
    if (this.#emptyPermissions || !this.#permissions.canCo(type))
      throw new Error(
        `Plugin does not have permission to read ${type} objects`,
      );

    return getContentObjects(getToken(), undefined, {
      contentTypeName: type,
      public: _public,
      ...query,
    });
  }

  postObject(type, object) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCo(type, RolePermissions.PERMISSIONS_TYPES.CREATE)
    )
      throw new Error(
        `Plugin does not have permission to create ${type} object`,
      );

    return postContentObject(getToken(), undefined, {
      contentTypeName: type,
      ...object,
    }).then((reponse) => {
      this.#clearContentObjectsCache(type);
      return reponse;
    });
  }

  putObject(type, id, object) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCo(type, RolePermissions.PERMISSIONS_TYPES.UPDATE)
    )
      throw new Error(
        `Plugin does not have permission to update ${type} objects`,
      );

    return putContentObject(getToken(), undefined, {
      id,
      contentTypeName: type,
      ...object,
    }).then((reponse) => {
      this.#clearContentObjectsCache(type, id);
      return reponse;
    });
  }

  patchObject(type, id, object) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCo(type, RolePermissions.PERMISSIONS_TYPES.UPDATE)
    )
      throw new Error(
        `Plugin does not have permission to update ${type} objects`,
      );

    return patchContentObject(getToken(), undefined, {
      id,
      contentTypeName: type,
      ...object,
    }).then((reponse) => {
      this.#clearContentObjectsCache(type, id);
      return reponse;
    });
  }

  deleteObject(type, id) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCo(type, RolePermissions.PERMISSIONS_TYPES.DELETE)
    )
      throw new Error(
        `Plugin does not have permission to delete ${type} objects`,
      );

    return deleteContentObject(getToken(), undefined, {
      id,
      contentTypeName: type,
    }).then((reponse) => {
      this.#clearContentObjectsCache(type, id);
      return reponse;
    });
  }

  getContentTypes(params) {
    if (this.#emptyPermissions || !this.#permissions.canCtd('*'))
      throw new Error(
        'Plugin does not have permission to read all definition types',
      );

    return listContentTypes(getToken(), undefined, params);
  }

  getContentType(type) {
    if (this.#emptyPermissions || !this.#permissions.canCtd(type))
      throw new Error(
        `Plugin does not have permission to read ${type} definition type`,
      );

    return getContentType(getToken(), undefined, type);
  }

  putContentType(type, object) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCtd(type, RolePermissions.PERMISSIONS_TYPES.UPDATE)
    )
      throw new Error(
        `Plugin does not have permission to update ${type} definition type`,
      );

    return putContentType(getToken(), undefined, {
      contentTypeName: type,
      ...object,
    }).then((reponse) => {
      this.#clearContentTypesCache(type);
      return reponse;
    });
  }

  getMediaUrl(mediaData, height = 0, width = 0) {
    if (this.#emptyPermissions || !this.#permissions.canCo('_media'))
      throw new Error(`Plugin does not have permission to read media`);

    return getMediaUrl(mediaData, width, height);
  }
}

export { FlotiqScopedApiClient };
