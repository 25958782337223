import { useCallback, useEffect } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { isUserRoleAdmin } from '../../lib/helpers';
import LoaderPage from '../../pages/LoaderPage/LoaderPage';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ModalProvider } from '../../contexts/ModalContext';
import useSessionStorageState from 'use-session-storage-state';
import { useLoggedUser } from './useLoggedUser';

const ProtectedRoute = ({ children, redirect, testId }) => {
  const { t } = useTranslation();
  const [user] = useLocalStorageState('cms.user');
  const [impersonate, , { removeItem: removeImpersonation }] =
    useSessionStorageState('cms.impersonate');

  const [searchParams] = useSearchParams();
  const redirect_uri = searchParams.get('redirect_uri');

  const { isLoading: userLoading, user: userData } = useLoggedUser();

  useEffect(() => {
    const isRoleAdmin = isUserRoleAdmin(user);
    if (!isRoleAdmin && impersonate) removeImpersonation();
  }, [impersonate, removeImpersonation, user]);

  const removeImperonationOnError = useCallback(() => {
    setTimeout(() => {
      toast.error(t('Global.UserNotAuthenticated'));
      removeImpersonation();
    });
  }, [removeImpersonation, t]);

  let currentLocation = window.location.href;
  const publicUrl = (process.env.PUBLIC_URL || window.location.origin).replace(
    /\/{0,10}$/,
    '',
  );
  if (currentLocation.replace(/\/{0,10}$/, '') === publicUrl) {
    currentLocation = '';
  }

  if (redirect_uri && user?.token) {
    const url = new URL(redirect_uri);

    if (url.host === window.location.host) {
      return <Navigate to={url.pathname + url.search} />;
    }

    return (
      <Navigate
        to={'/access-api-key'}
        state={{
          redirect_uri,
          response_type: searchParams.get('response_type'),
          key_type: searchParams.get('key_type'),
          application_name: searchParams.get('application_name'),
        }}
      />
    );
  }

  if (user) {
    if (user.data?.limits_plan?.free_space_limit === undefined) {
      return <Navigate to={'/logout?reason=new-system-version'} replace />;
    } else if (userLoading) {
      return (
        <ModalProvider>
          <LoaderPage testId={testId} />
        </ModalProvider>
      );
    } else if (!userData) {
      if (impersonate) {
        removeImperonationOnError();
        return;
      }
      return <Navigate to={'/logout?reason=auth-error'} replace />;
    } else if (user.token) {
      return children ? children : <Outlet />;
    }
  } else if (redirect) {
    return redirect;
  }
  return (
    <Navigate
      to={
        !currentLocation
          ? '/login'
          : `/login?redirect_uri=${encodeURIComponent(currentLocation)}`
      }
      replace
    />
  );
};

export default ProtectedRoute;
