import { useCallback, useMemo } from 'react';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import useToken from '../useToken';
import { getContentObjects, getContentType } from '../../lib/flotiq-client';
import useSelectedSpace from '../useSelectedSpace';
import { useQuery } from '@tanstack/react-query';

const DEFAULT_PARAMS = {
  defaultParams: { page: 1, limit: 10 },
  defaultOptions: {},
};

export const useRelations = (
  relationsRawData,
  defaultParams = DEFAULT_PARAMS.defaultParams,
  defaultOptions = DEFAULT_PARAMS.defaultOptions,
) => {
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const {
    checkStatus = true,
    cancelUnfinishedRequests = true,
    pause = false,
    staleTime = 0,
    gcTime = 5 * 60 * 1000, //5 minutes
  } = defaultOptions || {};

  const relationsRawDataString = useMemo(() => {
    if (!relationsRawData?.length) return '[]';
    const minIdx = (defaultParams.page - 1) * defaultParams.limit;
    const limitedData = relationsRawData.filter(
      (_, idx) => idx >= minIdx && idx < minIdx + defaultParams.limit,
    );

    return JSON.stringify(
      Array.from(
        new Set(limitedData.map((relation) => relation.dataUrl).sort()),
      ),
    );
  }, [defaultParams.limit, defaultParams.page, relationsRawData]);

  const relationDataUris = useMemo(
    () => JSON.parse(relationsRawDataString),
    [relationsRawDataString],
  );

  const idsDict = useMemo(
    () =>
      relationDataUris.reduce((acc, element) => {
        const { contentTypeName, id } = element.match(
          /(?<contentTypeName>[^/]+)\/(?<id>[^/]+)$/,
        ).groups;
        if (!acc[contentTypeName]) acc[contentTypeName] = [];
        acc[contentTypeName].push(id);
        return acc;
      }, {}),
    [relationDataUris],
  );

  const getRelationsData = useCallback(
    async ({ signal }) => {
      let newDict = {};
      const newErrors = [];

      await Promise.all(
        Object.keys(idsDict).map(async (key) => {
          try {
            const requestOptions = cancelUnfinishedRequests ? { signal } : {};

            const ctoPromise = getContentObjects(
              jwt,
              space,
              {
                contentTypeName: key,
                ids: idsDict[key],
                limit: idsDict[key].length,
              },
              requestOptions,
            );
            const ctdPromise = getContentType(
              jwt,
              space,
              { contentTypeName: key },
              requestOptions,
            );
            const [ctoResult, ctdResult] = await Promise.all([
              ctoPromise,
              ctdPromise,
            ]);

            if (checkStatus) {
              checkResponseStatus(ctdResult.body, ctdResult.status);
              checkResponseStatus(ctoResult.body, ctoResult.status);
            }
            const def = ctdResult.body.metaDefinition;
            const firstTextField = def.order.find(
              (field) => def.propertiesConfig[field].inputType === 'text',
            );
            newDict[key] = {
              data: ctoResult.body.data,
              titleField: firstTextField || '',
              contentType: ctdResult.body,
            };
          } catch (e) {
            if (e instanceof DOMException && e.name === 'AbortError') return;
            newErrors.push(e);
          }
        }),
      );

      return { data: newDict, errors: newErrors.length ? newErrors : null };
    },
    [cancelUnfinishedRequests, checkStatus, idsDict, jwt, space],
  );

  const enabled = !!jwt && !pause && !!relationDataUris.length;

  const {
    isLoading: requestLoading,
    data: response,
    refetch: reload,
    isPlaceholderData,
  } = useQuery({
    queryKey: [
      'content-objects',
      'relations',
      { dataUris: relationDataUris },
      space,
    ],
    queryFn: getRelationsData,
    enabled,
    placeholderData: enabled ? { data: idsDict, errors: null } : undefined,
    staleTime,
    gcTime,
    retry: 0,
  });

  const { data, errors } = useMemo(
    () => ({
      data: response?.data || null,
      errors: response?.errors || null,
    }),
    [response],
  );

  const isLoading = requestLoading || isPlaceholderData;

  const pagination = useMemo(() => {
    if (!relationsRawData?.length || errors?.length) return {};

    let maxPages = Math.floor(relationsRawData.length / defaultParams.limit);
    if (relationsRawData.length % defaultParams.limit !== 0) maxPages++;

    return {
      total_pages: maxPages,
      total_count: relationsRawData.length,
      count: relationDataUris.length,
      current_page: defaultParams.page,
    };
  }, [
    defaultParams.limit,
    defaultParams.page,
    errors?.length,
    relationDataUris.length,
    relationsRawData?.length,
  ]);

  const result = [data, pagination, isLoading, errors, reload];
  Object.assign(result, {
    data,
    pagination,
    isLoading,
    errors,
    reload,
  });
  return result;
};
