import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../../lib/helpers';
import Tooltip from '../../Tooltip/Tooltip';

const dotColorClasses = {
  draft: 'bg-sky-200',
  archived: 'bg-red-200',
  review: 'bg-orange-200',
  public: 'bg-lime-200',
  modified: 'bg-yellow-200',
  saved: 'bg-lime-200',
};

const textColorClasses = {
  draft: 'text-sky-700 dark:text-sky-400',
  public: 'text-lime-700 dark:text-lime-500',
  modified: 'text-yellow-600 dark:text-yellow-400',
  saved: 'text-lime-700 dark:text-lime-500',
  archived: 'text-red-600 dark:text-red-400',
};

const TopbarStatus = ({ status, label, testId, tooltip }) => (
  <Tooltip
    tooltip={tooltip}
    tooltipPlacement={'bottomCenter'}
    phoneTooltipPlacement={'topCenter'}
    {...getTestProps(testId, `topbar-tootltip`, 'testId')}
  >
    <div
      className={twMerge(
        'flex gap-3 items-center font-bold',
        textColorClasses[status] || 'text-gray-600',
      )}
      {...getTestProps(testId, 'topbar-status')}
    >
      {label}
      <span
        className={twMerge(
          'rounded-full w-[18px] h-[18px]',
          dotColorClasses[status] || 'bg-gray-200',
        )}
      />
    </div>
  </Tooltip>
);

export default TopbarStatus;

TopbarStatus.propTypes = {
  /**
   * Object status
   */
  status: PropTypes.string,
  /**
   * Object status label
   */
  label: PropTypes.node,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

TopbarStatus.defaultProps = {};
