import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useUserSpaces } from './api/useUserSpaces';

const useSelectedSpace = () => {
  const { spaceSlug } = useParams();
  const { spaces } = useUserSpaces();

  const spaceId = useMemo(
    () => spaces?.find(({ slug }) => slug === spaceSlug)?.id,
    [spaceSlug, spaces],
  );

  const buildUrlWithSpace = useCallback(
    (url) => `/s/${spaceSlug}/${url}`,
    [spaceSlug],
  );
  return { space: spaceId, buildUrlWithSpace };
};

export default useSelectedSpace;
