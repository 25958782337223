import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';

// :: Components
import Card from '../Card/Card';

const PickCard = ({
  title,
  description,
  imageUrl,
  imageUrlLoading,
  thumbnail,
  imageOverlay,
  onClick,
  additionalContainerClasses,
  additionalImageClasses,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      onClick={onClick}
      body={
        <>
          <div className="dark:text-white" title={title}>
            <CopyToClipboard
              className="cursor-copy"
              text={title}
              onCopy={() => {
                toast.success(t('ObjectsOfType.Copied', { text: title }));
              }}
              {...getTestProps(testId, `copy-title`)}
            >
              <span onClick={(event) => event.stopPropagation()}>{title}</span>
            </CopyToClipboard>
          </div>
          <div className="text-sm text-slate-400 mt-auto">{description}</div>
        </>
      }
      imageUrl={imageUrl}
      imageUrlLoading={imageUrlLoading}
      thumbnail={thumbnail}
      imageOverlay={imageOverlay}
      additionalContainerClasses={twMerge(
        'bg-slate-50 border border-slate-100 dark:bg-slate-800 dark:border-slate-700 w-full h-full flex',
        additionalContainerClasses,
      )}
      additionalImageContainerClasses="shrink-0 h-40 xs:h-28 w-full bg-white dark:bg-gray-700"
      additionalBodyClasses="px-3 py-2.5 flex flex-col gap-4 h-full w-full break-words"
      additionalImageClasses={additionalImageClasses}
      {...getTestProps(testId, '', 'testId')}
    />
  );
};

export default PickCard;

PickCard.propTypes = {
  /**
   * Card title
   */
  title: PropTypes.node,
  /**
   * Card description under title
   */
  description: PropTypes.node,
  /**
   * Card image URL
   */
  imageUrl: PropTypes.string,
  /**
   * If card image is loading
   */
  imageUrlLoading: PropTypes.bool,
  /**
   * On card click callback
   */
  onClick: PropTypes.func,
  /**
   * Card image overlay
   */
  imageOverlay: PropTypes.node,
  /**
   * Card image container
   */
  thumbnail: PropTypes.node,
  /**
   * Additional card classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Additional image classes
   */
  additionalImageClasses: PropTypes.string,
  /**
   * Relations card test id
   */
  testId: PropTypes.string,
};

PickCard.defaultProps = {
  title: '',
  description: '',
  imageUrl: '',
  imageUrlLoading: false,
  thumbnail: '',
  imageOverlay: '',
  additionalImageClasses: '',
  additionalContainerClasses: '',
  testId: '',
};
