import { useCallback, useMemo } from 'react';
import { useAllSpaceConstraints } from './useAllSpaceConstraints';

const DEFAULT_OPTIONS = {};

export const useSpaceConstraint = (name, space, options = DEFAULT_OPTIONS) => {
  const {
    allConstraints,
    isLoading,
    reload: reloadAllConstraints,
    errors,
  } = useAllSpaceConstraints(space, options);

  const constraint = useMemo(
    () => allConstraints?.[name],
    [allConstraints, name],
  );

  const reload = useCallback(async () => {
    return reloadAllConstraints([name]);
  }, [reloadAllConstraints, name]);

  const result = [constraint, isLoading, errors, reload];
  Object.assign(result, {
    constraint,
    isLoading,
    errors,
    reload,
  });
  return result;
};
