import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { TailSpin } from 'react-loader-spinner';
import { twMerge } from 'tailwind-merge';
import Button from '../../../components/Button/Button';
import Tooltip from '../../../components/Tooltip/Tooltip';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import useToken from '../../../hooks/useToken';
import { getTestProps } from '../../../lib/helpers';
import {
  checkoutPlanProperty,
  updateSpacePlan,
} from '../../../lib/flotiq-client';
import {
  checkResponseStatus,
  ResponseError,
} from '../../../lib/flotiq-client/response-errors';
import {
  ArrowRightThinIcon,
  ArrowUpThinIcon,
  InformationCircleIcon,
  WarningTriangleIcon,
} from '../../../images/shapes';
import moment from 'moment/moment';
import { useSpaceLimits } from '../../../hooks/useSpaceLimits';

const Detail = ({ detail, isTooltipCenter, testId }) => {
  const { t } = useTranslation();

  if (!detail.percent || detail.percent < 90 || detail.limit <= 0)
    return (
      <div>
        <span className="font-bold text-lg">{detail.value}</span>
        {detail.value != null && ' / '}
        {detail.limitLabel}
      </div>
    );

  const isReached = detail.percent >= 100;
  const tooltipKey = isReached
    ? 'Spaces.LimitExceeded'
    : 'Spaces.LimitAlmostExceeded';

  return (
    <div>
      <span
        className={`font-bold text-lg ${
          isReached ? 'text-red-600 dark:text-red-400' : ''
        }`}
      >
        {detail.value}
      </span>
      {detail.value != null && ' / '}
      {detail.limitLabel}
      <Tooltip
        tooltip={t(tooltipKey, { label: detail.label })}
        tooltipPlacement={isTooltipCenter ? 'topCenter' : 'topRight'}
        phoneTooltipPlacement={isTooltipCenter ? 'topCenter' : 'topRight'}
        additionalClasses="w-fit inline ml-2"
        {...getTestProps(testId, 'exceeded', 'testId')}
      >
        <WarningTriangleIcon
          className={`inline-block w-4 mb-px align-text-bottom ${
            isReached ? 'text-red-600 dark:text-red-400' : 'text-orange-400'
          }`}
        />
      </Tooltip>
    </div>
  );
};

const SpaceLimits = ({
  id,
  planLimits,
  paymentDueDate,
  paymentStatus,
  isAdmin,
  isBuyExtraVisible,
  testId,
}) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const [loadingBuyExtra, setLoadingBuyExtra] = useState('');
  const [isSpaceSaving, setIsSpaceSaving] = useState(false);

  const spaceLimitOptions = useMemo(
    () => ({
      pause: !id || !isAdmin,
    }),
    [id, isAdmin],
  );

  const { data: resourcesSpaceDetails, errors } = useSpaceLimits(
    planLimits,
    null,
    id,
    spaceLimitOptions,
  );

  useApiErrorsToast(errors);

  const handleBuyExtra = useCallback(
    async (propertyName) => {
      try {
        // Button on loading
        setLoadingBuyExtra(propertyName);

        const { body, status } = await checkoutPlanProperty(jwt, null, {
          propertyName: propertyName,
          spaceId: id,
          uri: `${
            process.env.PUBLIC_URL || window.origin
          }/organization?spaceId=${id}&planId=${planLimits?.id}&planName=${
            planLimits?.name
          }`,
        });

        // Check responcse status
        checkResponseStatus(body, status);

        // Remove loading for extra
        setLoadingBuyExtra('');

        // Redirect to body url
        window.location.assign(body.url);
      } catch (e) {
        toast.error(t('Form.CommunicationErrorMessage'));
        setLoadingBuyExtra('');
      }
    },
    [jwt, id, planLimits?.id, planLimits?.name, t],
  );

  const ButtonBuyExtra = useCallback(
    ({ detail }) => {
      // Available extend options
      const availableToExtend = ['team_members_limit'];

      // Remove button if not include extend option
      if (!availableToExtend.includes(detail?.key)) {
        return;
      }

      return (
        <Button
          buttonSize={'sm'}
          as={'button'}
          buttonColor={'borderless'}
          onClick={() => handleBuyExtra(detail?.key)}
          iconImage={<ArrowUpThinIcon className="w-3 h-3" />}
          iconPosition={'end'}
          disabled={detail.limit === -1}
          additionalClasses={'ml-auto w-fit'}
          additionalChildrenClasses={'flex flex-row-reverse'}
          {...getTestProps(testId, `buy-extra-${detail.key}`)}
        >
          {t('AccountSettings.BuyExtra')}
          <TailSpin
            ariaLabel="tail-spin-loading"
            color="#0083FC"
            height="15"
            radius="1"
            width="20"
            wrapperClass="mr-2"
            visible={loadingBuyExtra === detail?.key}
          />
        </Button>
      );
    },
    [testId, t, loadingBuyExtra, handleBuyExtra],
  );

  const handleUpgradeSpace = useCallback(async () => {
    setIsSpaceSaving(true);

    try {
      const { body, status } = await updateSpacePlan(jwt, undefined, {
        id: id,
        planId: planLimits.id,
        period: 'month',
        uri: window.location.toString(),
      });
      checkResponseStatus(body, status);

      setIsSpaceSaving(false);

      if (body.status === 'payment_required') {
        window.location.assign(body.url);
      }
    } catch (error) {
      setIsSpaceSaving(false);

      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
        return;
      }

      toast.error(t('Spaces.Form.CouldntUpdate'));
    }
  }, [jwt, id, planLimits.id, t]);

  return (
    <>
      <div className="flex flex-wrap lg:flex-nowrap items-center justify-between mb-3 text-base lg:text-lg">
        <div className="pr-5">
          {t('Spaces.YourSpacePlan')}
          <span
            className="font-bold ml-1 xs:text-lg lg:text-xl"
            {...getTestProps(testId, 'plan-name')}
          >
            {planLimits?.visible_name}
          </span>
        </div>
        {paymentStatus === 'trial' ? (
          <div className="flex items-end">
            <span>{t('Spaces.FreeTrialEnds')}</span>
            <span className="font-bold xs:text-lg lg:text-xl pl-2 mb-[-2px]">
              {moment(paymentDueDate).format('DD.MM.YYYY')}
            </span>
            <Button
              buttonSize={'sm'}
              as={'button'}
              buttonColor={'borderless'}
              onClick={handleUpgradeSpace}
              iconImage={<ArrowRightThinIcon className="w-3 h-3" />}
              iconPosition={'end'}
              additionalClasses={'ml-auto w-fit px-2'}
              additionalChildrenClasses={'flex flex-row-reverse'}
              {...getTestProps(testId, `buy-now`)}
            >
              {t('AccountSettings.BuyNow')}
              <TailSpin
                ariaLabel="tail-spin-loading"
                color="#0083FC"
                height="15"
                radius="1"
                width="20"
                wrapperClass="mr-2"
                visible={isSpaceSaving}
              />
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>

      {resourcesSpaceDetails.map((detail) => (
        <div
          key={detail.key}
          className={twMerge(
            'py-1 md:py-2 grid gap-2 text-base items-center',
            'border-b border-slate-200 dark:border-slate-800 last:border-none',
            isBuyExtraVisible ? 'grid-cols-3' : 'grid-cols-2',
          )}
          {...getTestProps(testId, detail.key)}
        >
          <div>
            {detail.label}
            {detail.tooltip && (
              <Tooltip
                tooltip={detail.tooltip}
                phoneTooltipPlacement="topLeft"
                additionalClasses="inline-block align-middle px-1 md:px-2"
                {...getTestProps(testId, detail.key, 'testId')}
              >
                <InformationCircleIcon className="text-blue min-w-4 w-4" />
              </Tooltip>
            )}
          </div>
          <Detail
            detail={detail}
            isTooltipCenter={isBuyExtraVisible}
            {...getTestProps(testId, detail.key, 'testId')}
          />
          {isBuyExtraVisible && planLimits?.price > 0 && (
            <ButtonBuyExtra detail={detail} />
          )}
        </div>
      ))}
    </>
  );
};

export default SpaceLimits;

SpaceLimits.propTypes = {
  /**
   * Space details id
   */
  id: PropTypes.string,
  /**
   * Space details plan limits details
   */
  planLimits: PropTypes.any,
  /**
   * Space details payment due date
   */
  paymentDueDate: PropTypes.string,
  /**
   * Space details payment status
   */
  paymentStatus: PropTypes.string,
  /**
   * Space details if is admin
   */
  isAdmin: PropTypes.bool,
  /**
   * If buy extra button is visible
   */
  isBuyExtraVisible: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

SpaceLimits.defaultProps = {
  id: '',
  planLimits: {},
  isAdmin: false,
  isBuyExtraVisible: true,
  testId: '',
};
