import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StatusPill from '../../StatusPill/StatusPill';
import { twMerge } from 'tailwind-merge';

const VersionPill = ({ status, orderClasses }) => {
  const { t } = useTranslation();

  return (
    <StatusPill
      containerClasses={twMerge(
        'h-[20px] py-0 px-[10px] rounded-md inline-flex items-center justify-center w-max',
        'text-xs font-normal',
        orderClasses,
      )}
      color={status}
      status={t(`ObjectStatus.Status.${status}`)}
    />
  );
};

export default VersionPill;

VersionPill.propTypes = {
  /**
   * Content object status
   */
  status: PropTypes.string,
  /**
   * Classes for managing pill order
   */
  orderClasses: PropTypes.string,
};

VersionPill.defaultProps = {
  status: '',
  orderClasses: '',
};
