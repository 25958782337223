import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Components
import RequiredTemplate from '../../../components/RequiredTemplate/RequiredTemplate';
import Switch from '../../../components/Switch/Switch';

// :: Lib helpers
import { getTestProps } from '../../../lib/helpers';

const SectionActions = ({
  onChange,
  actions,
  errors,
  disabled,
  webhookType,
  testId,
}) => {
  const { t } = useTranslation();

  const actionsOptions = useMemo(() => {
    const actions = [
      { id: 'create', name: 'Create', label: t('Global.Create') },
      { id: 'update', name: 'Update', label: t('Global.Update') },
      { id: 'delete', name: 'Delete', label: t('Global.Delete') },
    ];

    if (webhookType !== 'sync') {
      actions.push(
        {
          id: 'publish',
          name: 'Publish',
          label: t('ObjectStatus.Transition.publish'),
        },
        {
          id: 'unpublish',
          name: 'Unpublish',
          label: t('ObjectStatus.Transition.unpublish'),
        },
        {
          id: 'archive',
          name: 'Archive',
          label: t('ObjectStatus.Transition.archive'),
        },
        {
          id: 'custom',
          name: 'Custom',
          label: t('Global.Custom'),
          describe: t('Webhooks.CustomActionDescription'),
        },
      );
    }

    return actions;
  }, [t, webhookType]);

  const actionsMap = useMemo(
    () => (actions || []).map(({ action }) => action),
    [actions],
  );

  return (
    <>
      <label
        className="block text-sm text-slate-400 mb-2"
        {...getTestProps(testId, `label`)}
      >
        {t('Webhooks.Actions')} <RequiredTemplate />
      </label>

      <div
        className={twMerge(
          'rounded-lg',
          errors ? 'border border-solid border-red' : 'mb-4',
        )}
        {...getTestProps(testId, `container`)}
      >
        {actionsOptions.map((el) => (
          <div className={'mb-3'} key={el.id}>
            <Switch
              label={el.label}
              name={el.name}
              checked={actionsMap?.indexOf(el.name) > -1}
              onChange={onChange}
              disabled={disabled}
              {...getTestProps(testId, `switch-${el.id}`, 'testId')}
            />
            {el.describe && (
              <span
                className="flex text-sm ml-14 max-w-[320px] dark:text-gray-200"
                {...getTestProps(testId, `switch-describe-${el.id}`)}
              >
                {el.describe}
              </span>
            )}
          </div>
        ))}
      </div>

      {errors && (
        <span
          className="block py-1 text-red mb-4 text-left text-sm"
          {...getTestProps(testId, `errors`)}
        >
          {errors}
        </span>
      )}
    </>
  );
};

export default SectionActions;

SectionActions.propTypes = {
  /**
   * Change handler
   */
  onChange: PropTypes.func,
  /**
   * Array of checked actions
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
    }),
  ),
  /**
   * Error validation text
   */
  errors: PropTypes.string,
  /**
   * Is form disabled
   */
  disabled: PropTypes.bool,
  /**
   * Webhook type (sync/async)
   */
  webhookType: PropTypes.string,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

SectionActions.defaultProps = {
  disabled: false,
  onChange: undefined,
  actions: [],
  errors: '',
  webhookType: '',
  testId: '',
};
