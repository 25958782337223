import PropTypes from 'prop-types';
import Loader from '../../Loader/Loader';
import { DownloadCircleIcon } from '../../../images/shapes';
import { useTranslation } from 'react-i18next';
import TopbarButton from './base/TopbarButton';
import TopbarActionButton from './base/TopbarActionButton';

const TopbarPublishButton = ({
  onChange,
  isLoading,
  disabled,
  actionButton,
  ...props
}) => {
  const { t } = useTranslation();

  if (actionButton) {
    return (
      <TopbarActionButton
        color="green"
        label={t('ObjectStatus.Transition.publish')}
        disabled={disabled}
        iconImage={<DownloadCircleIcon className="w-3 min-w-3 rotate-180" />}
        onClick={onChange}
        additionalIconClasses="ml-0.5 mr-3"
        additionalClasses="flex md:hidden"
      />
    );
  }

  return (
    <TopbarButton
      buttonColor={'green'}
      label={t(`ObjectStatus.Transition.publish`)}
      iconImage={
        isLoading ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          <DownloadCircleIcon className="w-3 min-w-3 rotate-180" />
        )
      }
      onClick={onChange}
      additionalClasses="hidden md:flex"
      disabled={disabled}
      {...props}
    />
  );
};

export default TopbarPublishButton;

TopbarPublishButton.propTypes = {
  /**
   * On transition callback
   */
  onChange: PropTypes.func.isRequired,
  /**
   * If transiting to new status
   */
  isLoading: PropTypes.bool,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If button is action button
   */
  actionButton: PropTypes.bool,
};

TopbarPublishButton.defaultProps = {
  isLoading: false,
  disabled: false,
  actionButton: false,
};
