import { useCallback } from 'react';
import { patchMedia } from '../../lib/flotiq-client/api-helpers';
import useToken from '../useToken';
import useSelectedSpace from '../useSelectedSpace';
import { useTranslation } from 'react-i18next';

const useMediaUpdateVariants = () => {
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const { t } = useTranslation();

  const updateVariants = useCallback(
    (mediaId, variants, isDeleting = false) => {
      if (!jwt || !mediaId) return;

      return patchMedia(
        jwt,
        space,
        mediaId,
        {
          variants,
        },
        t,
        isDeleting
          ? t('MediaEdit.Variant.Deleted')
          : t('MediaEdit.Variant.Updated'),
        isDeleting
          ? t('MediaEdit.Variant.DeleteError')
          : t('MediaEdit.Variant.UpdateError'),
      );
    },
    [jwt, t, space],
  );

  return { updateVariants };
};

export default useMediaUpdateVariants;
