import { useTranslation } from 'react-i18next';
import useToken from '../useToken';
import { useSelector } from '@xstate/store/react';
import { useCallback } from 'react';
import useSelectedSpace from '../useSelectedSpace';
import { uploadFiles } from '../../lib/flotiq-client/api-helpers';
import { useSpaceConstraint } from './useSpaceConstraint';
import { newMediaStore } from '../../lib/store/newMediaStore';

const useMediaUpload = () => {
  const jwt = useToken();
  const { t } = useTranslation();
  const { space } = useSelectedSpace();

  const { reload: reloadMediaSumSize } = useSpaceConstraint('media-sum-size');

  const onUpload = useCallback(
    async (files) => {
      if (!jwt || !files) return;

      const filesList = Array.from(files);
      if (!filesList.length) return;

      filesList.forEach((file) => (file.id = Date.now() + Math.random()));
      const mediaList = filesList.map((file) => ({
        media: file,
        state: 'uploading',
      }));

      newMediaStore.trigger.updateNewMedia({ newMedia: mediaList });
      const media = await uploadFiles(jwt, space, filesList, t);
      newMediaStore.trigger.updateNewMedia({ newMedia: media });

      reloadMediaSumSize();

      return media;
    },
    [jwt, space, t, reloadMediaSumSize],
  );

  const newMedia = useSelector(
    newMediaStore,
    (state) => state.context.newMedia,
  );

  const removeNewMedia = useCallback(
    () => newMediaStore.trigger.removeNewMedia(),
    [],
  );

  return { newMedia, onUpload, removeNewMedia };
};

export default useMediaUpload;
