import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { getTestProps } from '../../lib/helpers';
import ModalPanel from './ModalPanel/ModalPanel';

const Modal = ({ open, modalConfig, children, testId }) => {
  const bodyRef = useRef(undefined);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        initialFocus={bodyRef}
        as="div"
        className="relative z-40"
        onClose={() => true}
        {...getTestProps(testId, 'overlay')}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-250"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-250"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div ref={bodyRef}>
          {modalConfig && <ModalPanel {...modalConfig} />}
          {children}
        </div>
      </Dialog>
    </Transition>
  );
};

Modal.propTypes = {
  /**
   * Is open state
   */
  open: PropTypes.bool,
  /**
   * If close button should be hidden
   */
  modalConfig: PropTypes.shape(ModalPanel.propTypes),
  /**
   * Modals content
   */
  children: PropTypes.node,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Modal.defaultProps = {
  open: true,
  testId: '',
};

export default Modal;
