import {
  postLogin,
  postRegister,
  postRegisterAppSumo,
  getAccountActivation,
} from './auth-requests';
import {
  makeBatchJSONQuery,
  makeBodilessQuery,
  makeJSONQuery,
  makeJSONQueryWithGet,
} from './base-request';

export { postLogin, postRegister, postRegisterAppSumo, getAccountActivation };

export const listContentTypes = makeBodilessQuery('v1/internal/contenttype');

export const getContentType = makeBodilessQuery(
  'v1/internal/contenttype/{{contentTypeName}}',
);
export const putContentType = makeJSONQuery(
  'v1/internal/contenttype/{{contentTypeName}}',
  'PUT',
);
export const deleteContentType = makeBodilessQuery(
  'v1/internal/contenttype/{{contentTypeName}}',
  'DELETE',
);
export const postContentType = makeJSONQuery('v1/internal/contenttype', 'POST');

export const getContentTypes = makeBodilessQuery('v1/internal/contenttype');

export const getContentObjects = makeBodilessQuery(
  'v1/content/{{contentTypeName}}',
);
export const batchDeleteContentObjects = makeJSONQuery(
  'v1/content/{{contentTypeName}}/batch-delete',
);

export const postContentObject = makeJSONQuery(
  'v1/content/{{contentTypeName}}',
);
export const getContentObject = makeBodilessQuery(
  'v1/content/{{contentTypeName}}/{{id}}',
);
export const getContentObjectVersions = makeBodilessQuery(
  'v1/content/{{contentTypeName}}/{{id}}/version',
);
export const getContentObjectVersion = makeBodilessQuery(
  'v1/content/{{contentTypeName}}/{{id}}/version/{{versionNumber}}',
);
export const putContentObject = makeJSONQuery(
  'v1/content/{{contentTypeName}}/{{id}}',
  'PUT',
);
export const patchContentObject = makeJSONQuery(
  'v1/content/{{contentTypeName}}/{{id}}',
  'PATCH',
);
export const batchPatchContentObject = makeBatchJSONQuery(
  'v1/content/{{contentTypeName}}/batch',
  'PATCH',
);
export const deleteContentObject = makeBodilessQuery(
  'v1/content/{{contentTypeName}}/{{id}}',
  'DELETE',
);

export const batchDeleteMedia = makeJSONQuery(
  'v1/content/_media/batch-delete',
  'POST',
);
export const updateMedia = makeJSONQuery(
  'v1/content/_media/{{mediaId}}',
  'PATCH',
);
export const batchUpdateMedia = makeJSONQuery(
  'v1/content/_media/batch',
  'PATCH',
);

export const createTag = makeJSONQuery('v1/content/_tag', 'POST');

export const listUnsplashMedia = makeBodilessQuery('unsplash');

export const saveUnsplashMedia = makeBodilessQuery('unsplash/{{mediaId}}');

export const getConstraints = makeBodilessQuery('constraints/{{name}}');

export const getAllConstraints = makeBodilessQuery('constraints/all');

export const getUsers = makeBodilessQuery('users');

export const getUser = makeBodilessQuery('users/{{id}}');

export const putUser = makeJSONQuery('users/{{id}}', 'PUT');

export const putUserConfig = makeJSONQuery('users/{{id}}/config', 'PUT');

export const postUser = makeJSONQuery(
  'users?redirectUri={{redirectUri}}',
  'POST',
);

export const putUserChangeDefaultSpace = makeJSONQuery(
  'users/change-default-space/{{id}}',
  'PUT',
);

export const deleteUser = makeJSONQuery('users/{{id}}', 'DELETE');

export const getAllUsers = makeBodilessQuery('users-data-preview/users');
export const activateUser = makeBodilessQuery(
  'users/activate-user/{{id}}',
  'PUT',
);
export const resendActivationEmail = makeBodilessQuery(
  'users/resend-activation-email/{{id}}',
  'PUT',
);

export const getAllSpaces = makeBodilessQuery('spaces-data-preview/spaces');

export const postMedia = makeJSONQuery('media');

export const getSearchObject = makeBodilessQuery('v1/search');

export const deleteAccountRequest = makeJSONQuery(
  'delete-account-request?redirectUri={{redirectUri}}',
  'POST',
);
export const deleteAccount = makeJSONQuery('delete-account', 'POST');

export const getApiKey = makeBodilessQuery('api-key');

export const deleteApiKey = makeBodilessQuery('api-key/{{id}}', 'DELETE');

export const regenerateApiKey = makeJSONQuery(
  'api-key/{{id}}/regenerate',
  'PUT',
);

export const updateApiKey = makeJSONQuery('api-key/{{id}}', 'PUT');

export const postApiKey = makeJSONQuery('api-key', 'POST');

export const putWebhooks = makeJSONQuery('v1/content/_webhooks/{{id}}', 'PUT');

export const postWebhooks = makeJSONQuery('v1/content/_webhooks', 'POST');

export const deleteWebhooks = makeBodilessQuery(
  'v1/content/_webhooks/{{id}}',
  'DELETE',
);

export const listWebhooks = makeBodilessQuery('v1/content/_webhooks', 'GET');

export const getWebhooks = makeBodilessQuery('v1/content/_webhooks/{{id}}');

export const executeWebhooks = makeJSONQuery(
  'v1/content/_webhooks/{{id}}/execute',
  'POST',
);

export const versionWebhooks = makeBodilessQuery(
  'v1/content/_webhooks/{{id}}/version',
);

export const logsWebhooks = makeBodilessQuery(
  'v1/content/_webhooks/{{id}}/logs',
);

export const publishContentObject = makeBodilessQuery(
  'v1/content/{{objectType}}/{{objectId}}/publish',
  'POST',
);

export const unpublishContentObject = makeBodilessQuery(
  'v1/content/{{objectType}}/{{objectId}}/unpublish',
  'POST',
);

export const archiveContentObject = makeBodilessQuery(
  'v1/content/{{objectType}}/{{objectId}}/archive',
  'POST',
);

export const checkoutPlanProperty = makeJSONQuery(
  'order/checkout/property?space={{spaceId}}&redirectUri={{uri}}',
  'POST',
);

export const checkoutPlan = makeJSONQuery(
  'order/checkout?redirectUri={{uri}}',
  'POST',
);

export const getAllRolesAssigned = makeBodilessQuery('role/user/{{id}}/all');

export const getAllUserRoles = makeBodilessQuery('role/all');

export const getUserRoles = makeBodilessQuery('role');

export const getUserRole = makeBodilessQuery('role/{{id}}');

export const putUserRole = makeJSONQuery('role/{{id}}', 'PUT');

export const deleteUserRole = makeJSONQuery('role/{{id}}', 'DELETE');

export const postUserRole = makeJSONQuery('role', 'POST');

export const assignUserRole = makeJSONQueryWithGet('role/assign/{{id}}', 'PUT');

export const unassignUserRole = makeJSONQueryWithGet(
  'role/unassign/{{id}}',
  'DELETE',
);

export const getPublicPlans = makeBodilessQuery('plans/public', 'GET');
export const getPlans = makeBodilessQuery('plans', 'GET');

export const getPlan = makeBodilessQuery('plans/{{id}}', 'GET');
export const postPlan = makeJSONQuery('plans', 'POST');
export const putPlan = makeJSONQuery('plans/{{id}}', 'PUT');
export const deletePlan = makeJSONQuery('plans/{{id}}', 'DELETE');

export const postOrder = makeJSONQuery(
  'order/dashboard?user={{userId}}&redirectUri={{uri}}',
  'POST',
);

export const getSpace = makeBodilessQuery('v1/spaces/{{id}}', 'GET');
export const putSpace = makeJSONQuery('v1/spaces/{{id}}', 'PUT');
export const deleteSpace = makeJSONQuery('v1/spaces/{{id}}', 'DELETE');

export const putSpaceHasPlan = makeJSONQuery(
  'v1/spaces/{{id}}/override-plan',
  'PUT',
);

export const getSpacesList = makeBodilessQuery('v1/spaces', 'GET');

export const getUserSpaces = makeBodilessQuery('v1/spaces/user-spaces', 'GET');
export const getUserDefaultSpace = makeBodilessQuery(
  'v1/spaces/default-space',
  'GET',
);

export const postSpace = makeJSONQuery('v1/spaces?redirectUri={{uri}}', 'POST');
export const updateSpacePlan = makeJSONQuery(
  'v1/spaces/{{id}}/change-plan?redirectUri={{uri}}',
  'PUT',
);

export const getSpacePlanHistory = makeBodilessQuery('plans/space/{{id}}');
