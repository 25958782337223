import { useCallback, useEffect, useRef } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { ModalProvider } from '../../contexts/ModalContext';
import LoaderPage from '../../pages/LoaderPage/LoaderPage';
import useSessionStorageState from 'use-session-storage-state';
import { useUserSpaces } from '../../hooks/api/useUserSpaces';
import { useUserDefaultSpaceSlug } from '../../hooks/api/useUserDefaultSpaceSlug';

const NavigatePage = ({ testId, children }) => {
  const navigate = useNavigate();
  const { spaceSlug } = useParams();
  const { t } = useTranslation();
  const [impersonate] = useSessionStorageState('cms.impersonate');
  const toastRef = useRef();

  const { spaces, isLoading: spacesLoading, errors } = useUserSpaces();

  const {
    defaultSpaceSlug,
    isLoading: defaultSpaceLoading,
    updateDefaultSpace,
  } = useUserDefaultSpaceSlug();

  const isLoading = spacesLoading || defaultSpaceLoading;

  useEffect(() => {
    if (errors) {
      if (toastRef.current) toast.remove(toastRef.current);
      toastRef.current = toast.error(t('Global.GetSpacesError'));
    }
  }, [errors, t]);

  const handleEntranceInSpace = useCallback(
    async (spaces, spaceSlug, defaultSpaceSlug) => {
      if (spaceSlug) {
        const existSpace = spaces.find((el) => el.slug === spaceSlug);
        const currentSpaceId = existSpace?.id;

        if (!currentSpaceId) {
          toast.error(t('Spaces.ErrorWrongSpaceIdRedirectToDefault'));
          navigate('/');
          return;
        }

        if (existSpace?.slug !== defaultSpaceSlug && !impersonate) {
          updateDefaultSpace(existSpace.id, existSpace.slug);
        }

        if (!impersonate) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'space_changed',
              space_id: currentSpaceId,
              plan_id: existSpace?.planLimits?.id,
              plan_name: existSpace?.planLimits?.name,
            },
          });
        }
      } else if (defaultSpaceSlug) {
        navigate(`/s/${defaultSpaceSlug}`);
      } else {
        navigate(`/s/${spaces[0].slug}`);
      }
    },
    [impersonate, t, navigate, updateDefaultSpace],
  );

  useEffect(() => {
    if (isLoading) return;

    if (!spaces?.length) {
      navigate('/start');
      return;
    }

    handleEntranceInSpace(spaces, spaceSlug, defaultSpaceSlug);
  }, [
    defaultSpaceSlug,
    handleEntranceInSpace,
    isLoading,
    navigate,
    spaceSlug,
    spaces,
  ]);

  const currentChildren = children ? children : <Outlet />;

  return (
    <ModalProvider>
      {isLoading ? <LoaderPage testId={testId} /> : currentChildren}
    </ModalProvider>
  );
};

export default NavigatePage;
