import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Component
import Button from '../Button/Button';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Icons
import { CloseIcon } from '../../images/shapes';

const AnnouncementComponent = ({
  additionalClasses,
  announcement,
  handleClose,
  hidden,
  hasCloseButton,
  testId,
}) => {
  if (!announcement) return null;

  return (
    <div
      className={twMerge(
        'flex w-full justify-center items-center fixed z-30 bg-gradient-featured-bar',
        hidden && 'hidden',
        additionalClasses,
      )}
      {...getTestProps(testId, 'container')}
    >
      {typeof announcement === 'string' ? (
        <div
          className="w-full pr-10 lg:pr-12"
          dangerouslySetInnerHTML={{
            __html: announcement,
          }}
        />
      ) : (
        announcement
      )}

      {hasCloseButton && (
        <div
          className={twMerge(
            'flex items-center h-full',
            'absolute top-0 right-0 w-10 lg:w-12',
          )}
        >
          <Button
            iconImage={
              <CloseIcon className="text-white hover:opacity-50 w-4 lg:w-5" />
            }
            buttonColor="borderless"
            noPaddings
            onClick={handleClose}
            {...getTestProps(testId, 'close-button-announcement', 'testId')}
          />
        </div>
      )}
    </div>
  );
};

AnnouncementComponent.propTypes = {
  /**
   * Has close button
   */
  hasCloseButton: PropTypes.bool,
  /**
   * Additional classes for pagination box
   */
  additionalClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
  /**
   * Hide component
   */
  hidden: PropTypes.bool,
  /**
   * Announcement to display
   */
  announcement: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Handle Close button
   */
  handleClose: PropTypes.func,
};

AnnouncementComponent.defaultProps = {
  additionalClasses: '',
  announcement: '',
  handleClose: undefined,
  hasCloseButton: false,
  hidden: false,
  testId: '',
};

export default AnnouncementComponent;
