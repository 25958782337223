import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormatedSize } from '../lib/helpers';
import CheckCircle from '../components/CheckCircle/CheckCircle';
import { useAllSpaceConstraints } from './api/useAllSpaceConstraints';

const DEFAULT_OPTIONS = {};

const limitsDictionary = {
  cto_limit: {
    constraint: 'content-objects-count',
    translationKey: 'ContentTypeObjects',
  },
  ctd_limit: {
    constraint: 'content-definitions-count',
    translationKey: 'ContentTypeDefinitions',
  },
  file_quota: {
    constraint: 'media-sum-size',
    translationKey: 'FileQuota',
    format: 'file-size',
  },
  max_asset_size: {
    translationKey: 'MaxAssetSize',
    format: 'file-size',
  },
  scoped_keys_limit: {
    constraint: 'scoped-keys-count',
    translationKey: 'ScopedApiKeysCount',
  },
  scoped_keys_docs: {
    translationKey: 'ScopedApiKeysDocs',
    format: (limit) => (
      <CheckCircle checked={!!limit} additionalClasses="w-5" />
    ),
  },
  team_members_limit: {
    constraint: 'team-members-count',
    translationKey: 'TeamMembers',
  },
  webhooks_limit: {
    constraint: 'webhooks-count',
    translationKey: 'Webhooks',
  },
  hosted_webhooks_limit: {
    constraint: 'hosted-webhooks-count',
    translationKey: 'HostedWebhook',
  },
  api_calls_limit: {
    constraint: 'api-calls-count',
    translationKey: 'APICalls',
  },
  official_plugins_limit: {
    constraint: 'official-plugins-count',
    translationKey: 'OfficialPlugins',
  },
  custom_plugins_limit: {
    constraint: 'custom-plugins-count',
    translationKey: 'CustomPlugins',
  },
  allowed_hosts_for_custom_plugins: {
    translationKey: 'AllowedHosts',
    format: (limit, t) => {
      const hosts = limit?.split(',');
      if (!hosts || !hosts.length) return '-';
      if (hosts.includes('*')) return t('Global.Unlimited');
      return hosts;
    },
  },
  user_roles_enabled: {
    translationKey: 'UserRoles',
    isBoolean: true,
    format: (limit) => (
      <CheckCircle checked={!!limit} additionalClasses="w-5" />
    ),
  },
};

const getProgressInformation = (count, limit) => {
  if (typeof limit === 'boolean') return {};
  return limit === -1
    ? {
        percent: -1,
        hidePercentages: true,
      }
    : {
        percent: (count / limit) * 100,
      };
};

export const useSpaceLimits = (
  planLimits,
  planLimitNames,
  space,
  options = DEFAULT_OPTIONS,
) => {
  const { t } = useTranslation();

  const { allConstraints, isLoading, errors } = useAllSpaceConstraints(
    space,
    options,
  );

  const data = useMemo(
    () =>
      (planLimitNames?.length
        ? planLimitNames
        : Object.keys(limitsDictionary)
      ).map((key) => {
        const constraint = limitsDictionary[key].constraint;
        const value = isLoading ? undefined : allConstraints?.[constraint];
        const planLimit = planLimits?.[key];
        const translationKey = limitsDictionary[key].translationKey;
        const format = limitsDictionary[key].format;
        return {
          key,
          label: t(`Global.${translationKey}`),
          limit: planLimit,
          limitLabel:
            typeof format === 'function'
              ? format(planLimit, t)
              : getFormatedSize(planLimit, t('Global.Unlimited'), format),
          value: getFormatedSize(value, t('Global.Unlimited'), format),
          tooltip: t(`Limits.Description.${translationKey}`),
          isUnlimited: planLimit === -1,
          ...getProgressInformation(value, planLimit),
          ...(isLoading ? { hidePercentages: true } : {}),
        };
      }),
    [allConstraints, isLoading, planLimitNames, planLimits, t],
  );

  const result = [data, isLoading, errors];
  Object.assign(result, {
    data,
    isLoading,
    errors,
  });
  return result;
};
