import { createStore } from '@xstate/store';

export const newMediaStore = createStore({
  context: { newMedia: [] },
  on: {
    removeNewMedia: (context) => ({
      newMedia: context.newMedia.filter(({ state }) => state === 'uploading'),
    }),
    updateNewMedia: (context, { newMedia }) => {
      const newList = [...context.newMedia];
      newMedia.forEach((uploadedMedia) => {
        const { media, state } = uploadedMedia;
        const index = newList.findIndex(({ media: oldMedia }) => {
          if (state === 'unsplash')
            return (
              oldMedia['id'] === media['externalId'] ||
              oldMedia['externalId'] === media['externalId']
            );
          if (state === 'uploaded')
            return oldMedia['name'] === media['fileName'];
          return oldMedia['id'] === media['id'];
        });
        if (index > -1) newList[index] = uploadedMedia;
        else newList.push(uploadedMedia);
      });
      return { newMedia: newList };
    },
    clear: () => ({ newMedia: [] }),
  },
});
