import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from '@headlessui/react';
import {
  DndProvider,
  MouseTransition,
  MultiBackend,
  TouchTransition,
} from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import useLocalStorageState from 'use-local-storage-state';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// :: Router
import AppRouter from '../../router/AppRouter';

// :: Context
import { AppContext } from '../../contexts/AppContext';

// :: Components
import Toast from '../../components/Toast/Toast';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';

const INITIAL_STATE = {
  fullName: 'Flotiq Page',
  language: 'en',
  page: 'dashboard',
};

const App = () => {
  const { i18n } = useTranslation();
  const [appContext, setAppContext] = useState(INITIAL_STATE);
  const [user] = useLocalStorageState('cms.user');
  const [debuggerEnabled] = useLocalStorageState('cms.debugger');
  const userLang = useMemo(() => user?.data?.language, [user]);

  const providerValue = useMemo(
    () => ({ appContext, updateAppContext: setAppContext }),
    [appContext],
  );

  useEffect(() => {
    if (!user) {
      if (FlotiqPlugins.getLoadedPluginsIds().length) window.location.reload();
    }
  }, [user]);

  useEffect(() => {
    setAppContext((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        language: userLang,
      },
    }));

    if (userLang !== i18n.language) {
      i18n.changeLanguage(userLang);
    }
  }, [userLang, i18n]);

  const HTML5toTouch = useMemo(
    () => ({
      backends: [
        {
          id: 'html5',
          backend: HTML5Backend,
          transition: MouseTransition,
        },
        {
          id: 'touch',
          backend: TouchBackend,
          options: { enableMouseEvents: true },
          preview: true,
          transition: TouchTransition,
        },
      ],
    }),
    [],
  );

  return (
    <>
      <Portal>
        <Toast />
      </Portal>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <AppContext.Provider value={providerValue}>
          <AppRouter />
        </AppContext.Provider>
      </DndProvider>
      {debuggerEnabled === true && <ReactQueryDevtools />}
    </>
  );
};

export default App;
