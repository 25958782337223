import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import useToken from '../useToken';
import { getQueryCacheKey } from '../../lib/helpers';
import { getUserSpaces } from '../../lib/flotiq-client';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import useLocalStorageState from 'use-local-storage-state';
import useSessionStorageState from 'use-session-storage-state';

const PARAMS = {
  limit: 1000,
  page: 1,
};

export const useUserSpaces = () => {
  const jwt = useToken();
  const [user] = useLocalStorageState('cms.user');
  const [impersonate] = useSessionStorageState('cms.impersonate');

  const cacheUserId = useMemo(
    () => (impersonate ? impersonate.id : user?.data?.id),
    [impersonate, user?.data?.id],
  );

  const queryKey = useMemo(
    () =>
      getQueryCacheKey('spaces', ['user-spaces', cacheUserId], PARAMS, 'list'),
    [cacheUserId],
  );

  const {
    isLoading: requestLoading,
    data: response,
    refetch: reload,
    isPlaceholderData,
  } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      try {
        const { body, status } = await getUserSpaces(jwt, undefined, PARAMS, {
          signal,
        });
        checkResponseStatus(body, status);

        return { spaces: body?.data, errors: null };
      } catch (e) {
        return { spaces: [], errors: e };
      }
    },
    enabled: !!jwt,
    staleTime: Infinity,
    gcTime: 0,
    retry: 0,
  });

  const isLoading = requestLoading || isPlaceholderData;

  const { spaces = [], errors = null } = response || {};

  const result = [spaces, isLoading, errors, reload];
  Object.assign(result, {
    spaces,
    isLoading,
    errors,
    reload,
  });
  return result;
};
