import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import useToken from '../useToken';
import { getQueryCacheKey } from '../../lib/helpers';
import useSessionStorageState from 'use-session-storage-state';
import useLocalStorageState from 'use-local-storage-state';
import {
  getUserDefaultSpace,
  putUserChangeDefaultSpace,
} from '../../lib/flotiq-client';
import {
  checkResponseStatus,
  ResponseError,
} from '../../lib/flotiq-client/response-errors';

export const useUserDefaultSpaceSlug = () => {
  const jwt = useToken();
  const queryClient = useQueryClient();

  const [impersonate] = useSessionStorageState('cms.impersonate');
  const [user] = useLocalStorageState('cms.user');
  const userId = user?.data?.id;

  const cacheUserId = useMemo(
    () => (impersonate ? impersonate.id : user?.data?.id),
    [impersonate, user?.data?.id],
  );

  const queryKey = useMemo(
    () => getQueryCacheKey('spaces', ['default-slug', cacheUserId]),
    [cacheUserId],
  );

  const {
    isLoading: requestLoading,
    data: defaultSpaceSlug,
    refetch: reload,
    isPlaceholderData,
    error: errors,
  } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const result = await getUserDefaultSpace(jwt, undefined, undefined, {
        signal,
      });
      checkResponseStatus(result.body, result.status);

      return result.body?.slug;
    },
    enabled: !!jwt,
    staleTime: Infinity,
    gcTime: 0,
    retry: 0,
  });

  const isLoading = requestLoading || isPlaceholderData;

  const updateDefaultSpace = useCallback(
    async (spaceId, spaceSlug) => {
      if (impersonate) return;

      if (userId && spaceId && spaceSlug) {
        try {
          const { body, status } = await putUserChangeDefaultSpace(
            jwt,
            undefined,
            {
              id: userId,
              spaceId: spaceId,
            },
          );

          checkResponseStatus(body, status);
          queryClient.setQueryData(queryKey, () => spaceSlug);
        } catch (error) {
          if (error instanceof ResponseError) {
            Sentry.captureException(
              new Error(`Couldn't put default space`, error),
            );
          }
        }
      }
    },
    [impersonate, jwt, queryClient, queryKey, userId],
  );

  const result = [
    defaultSpaceSlug,
    isLoading,
    errors,
    reload,
    updateDefaultSpace,
  ];
  Object.assign(result, {
    defaultSpaceSlug,
    isLoading,
    errors,
    reload,
    updateDefaultSpace,
  });
  return result;
};
