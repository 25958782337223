import { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

// :: Components
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';

// :: Context
import UserContext from '../contexts/UserContext';

// :: Hooks
import useDarkMode from '../hooks/useDarkMode';
import useToken from '../hooks/useToken';

// :: Images
import {
  LogOutIcon,
  FourSquaresColorWhite,
  UsersIconWhite,
  ArrowLeftIcon,
  FourSquaresColor,
  UsersIcon,
  AvatarIcon,
  HouseIcon,
  SunIcon,
  MoonIcon,
  CardPaymentIcon,
  CardPaymentIconWhite,
} from '../images/shapes';

// :: Layout
import Structure from './Structure';

// :: Lib
import { manageSubscription } from '../lib/flotiq-client/api-helpers';
import { getStripeURL } from '../lib/helpers';

const LayoutSlim = ({ template, children, testId }) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const { userData, userStorage, isAdmin } = useContext(UserContext);
  const [darkMode, setDarkMode] = useDarkMode();

  const manageSubscriptionClick = useCallback(
    () => manageSubscription(jwt, userStorage?.data?.id, t),
    [jwt, t, userStorage?.data?.id],
  );

  const SIDEBAR_MENU_ITEMS = useMemo(() => {
    if (template === 'settings') {
      const menuItems = [
        {
          key: 'backToDashboard',
          icon: (
            <ArrowLeftIcon className="w-6 min-w-6 p-1 text-black dark:text-white" />
          ),
          title: t('Global.BackToTheDashboard'),
          link: '/',
          titleClassName: 'text-red underline',
          className: `h-10 hover:bg-transparent`,
        },
      ];

      if (isAdmin) {
        menuItems.push({
          key: 'spaces-and-usage',
          icon: darkMode ? (
            <FourSquaresColorWhite className="w-6 min-w-6" />
          ) : (
            <FourSquaresColor className="w-6 min-w-6" />
          ),
          title: t('Spaces.SpacesAndUsage'),
          link: '/organization',
        });
        menuItems.push({
          key: 'users',
          icon: darkMode ? (
            <UsersIconWhite className="w-6 min-w-6" />
          ) : (
            <UsersIcon className="w-6 min-w-6" />
          ),
          title: t('Global.Users'),
          link: '/users',
        });

        const isBillingDisabled = !userData?.paymentClientId;
        const billingTooltip = isBillingDisabled
          ? t('AccountSettings.NoSubscription')
          : t('AccountSettings.ManageSubscriptionText') +
            ' ' +
            t('AccountSettings.ManageSubscriptionLinkText');

        const stripeURL = getStripeURL();
        if (stripeURL) {
          menuItems.push({
            key: 'billing',
            icon: darkMode ? (
              <CardPaymentIconWhite className="w-6 min-w-6" />
            ) : (
              <CardPaymentIcon className="w-6 min-w-6" />
            ),
            title: t('Global.Billing'),
            onClick: manageSubscriptionClick,
            tooltip: billingTooltip,
            disabled: isBillingDisabled,
            titleClassName: twMerge(
              'after:ml-2 after:content-["↗"] after:inline-block',
              !isBillingDisabled &&
                'group-hover:underline group-hover:after:no-underline',
            ),
          });
        }
      }

      return menuItems;
    }
  }, [
    template,
    t,
    isAdmin,
    darkMode,
    userData?.paymentClientId,
    manageSubscriptionClick,
  ]);

  const MENU_USER_ITEMS = useMemo(() => {
    const currentMenuUserItems = [
      {
        id: 'dark-mode-change',
        onClick: () => setDarkMode(!darkMode),
        text: darkMode
          ? t('Global.ChooseLightTheme')
          : t('Global.ChooseDarkTheme'),
        icon: darkMode ? (
          <SunIcon className="w-4 text-white" />
        ) : (
          <MoonIcon className="w-4 text-indigo-950" />
        ),
      },
      {
        id: 'profile-page',
        link: '/profile',
        text: t('Global.ProfileSettings'),
        icon: (
          <AvatarIcon className="text-indigo-950 dark:text-white w-4 h-4 " />
        ),
      },
      {
        id: 'logout',
        link: '/logout',
        text: t('Global.LogOut'),
        icon: (
          <LogOutIcon className="text-indigo-950 dark:text-white w-4 h-4 " />
        ),
      },
    ];

    if (isAdmin) {
      currentMenuUserItems.splice(1, 0, {
        id: 'organization-page',
        link: '/organization',
        text: t('Global.OrganizationSettings'),
        icon: <HouseIcon className="w-4 h-4 text-indigo-950 dark:text-white" />,
      });
    }

    return currentMenuUserItems;
  }, [t, isAdmin, setDarkMode, darkMode]);

  const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

  return (
    <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
      <Structure
        sidebarMenuItems={SIDEBAR_MENU_ITEMS}
        menuUserItems={MENU_USER_ITEMS}
        testId={testId}
      >
        {children ? children : <Outlet />}
      </Structure>
    </Sentry.ErrorBoundary>
  );
};

export default LayoutSlim;

LayoutSlim.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
  /**
   * template
   */
  template: PropTypes.string,
};

LayoutSlim.defaultProps = {
  testId: '',
  template: '',
};
