import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import ContentObjectFormContext from '../../../contexts/ContentObjectFormContext';
import { useContext, useEffect } from 'react';
import ElementFromPlugin from '../../../components/ElementFromPlugin/ElementFromPlugin';
import { FormAddElementEvent } from '../../../lib/flotiq-plugins/plugin-events/FormAddElementEvent';
import usePluginResults from '../../../hooks/usePluginResults';
import useMediaUpload from '../../../hooks/api/useMediaUpload';

const CustomFormElement = ({ setCount }) => {
  const { contentType, initialData, userPlugins, formUniqueKey } = useContext(
    ContentObjectFormContext,
  );
  const { onUpload: onMediaUpload } = useMediaUpload();
  const formik = useFormikContext();

  /**
   * @emits FlotiqPlugins."flotiq.form::add"
   */
  const pluginRenders = usePluginResults(
    'flotiq.form::add',
    FormAddElementEvent,
    {
      contentType,
      formik,
      onMediaUpload,
      initialData,
      userPlugins,
      formUniqueKey,
    },
  );

  useEffect(() => {
    setCount?.(pluginRenders?.length);
  }, [pluginRenders?.length, setCount]);

  return pluginRenders?.length ? (
    <ElementFromPlugin results={pluginRenders} />
  ) : null;
};

export default CustomFormElement;

CustomFormElement.propTypes = {
  /**
   * Callback to set plugin renders count
   */
  setCount: PropTypes.func,
};
