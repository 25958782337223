import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import useLocalStorageState from 'use-local-storage-state';

// :: Components
import Tabs from '../../components/Tabs/Tabs';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Tooltip from '../../components/Tooltip/Tooltip';
import LinkButton from '../../components/LinkButton/LinkButton';

// :: Contexts
import UserContext from '../../contexts/UserContext';

// :: Subpages
import UserMedia from './Subpages/UserMedia';
import UnsplashMedia from './Subpages/UnsplashMedia';

// :: Lib
import { getFormatedSize, getTestProps } from '../../lib/helpers';
import { RolePermissions } from '../../lib/rolePermissions';

// :: Images
import { WarningIcon } from '../../images/shapes';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import PageLayout from '../../layout/PageLayout/PageLayout';
import TopbarButton from '../../components/Topbar/buttons/base/TopbarButton';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { useSpaceConstraint } from '../../hooks/api/useSpaceConstraint';
import useMediaUpload from '../../hooks/api/useMediaUpload';

const MediaLibrary = ({ testId }) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const { space } = useSelectedSpace();

  const { planLimits, permissions, isAdmin } = useContext(UserContext);
  const canCreate = permissions.canCo(
    '_media',
    RolePermissions.PERMISSIONS_TYPES.CREATE,
  );

  const { onUpload } = useMediaUpload();
  const [user] = useLocalStorageState('cms.user');

  const [selectedMedia, setSelectedMedia] = useState({});

  const selectMedia = useCallback(
    (media) =>
      setSelectedMedia((prev) => {
        const newSelectedMedia = { ...prev };
        if (newSelectedMedia[media.id]) delete newSelectedMedia[media.id];
        else newSelectedMedia[media.id] = media;
        return newSelectedMedia;
      }),
    [],
  );

  const clearSelection = useCallback(() => setSelectedMedia({}), []);

  const [mediaOnPageLimit, setMediaOnPageLimit] = useState(
    // We are using +`${x}` interpolation instead of +x
    // to prevent React from evaluating the entire expression
    // during a build.
    +`${process.env.REACT_APP_DEFAULT_MEDIA_LIMIT}` || 12,
  );

  const { constraint: mediaSumSize, reload: reloadConstraint } =
    useSpaceConstraint('media-sum-size');

  const handleUpload = useCallback(
    async (e) => {
      onUpload(e.target.files);
      if (inputRef.current) inputRef.current.value = null;
    },
    [onUpload],
  );

  const handleReloadMedia = useCallback(() => {
    reloadConstraint();
  }, [reloadConstraint]);

  const tabsContent = useMemo(() => {
    return [
      {
        label: (
          <span className={'!text-sm sm:!text-base'}>
            {t('Media.Tabs.UploadedFiles')}
          </span>
        ),
        key: 'UserMediaTab',
        render: () => (
          <UserMedia
            mediaOnPageLimit={mediaOnPageLimit}
            setMediaOnPageLimit={setMediaOnPageLimit}
            onUpload={onUpload}
            onRemove={handleReloadMedia}
            selectedMedia={selectedMedia}
            selectMedia={selectMedia}
            clearSelection={clearSelection}
            testId={testId}
            hasGridView
          />
        ),
      },
      {
        label: (
          <span className={'!text-sm sm:!text-base'}>
            {t('Media.Tabs.UnsplashFiles')}
          </span>
        ),
        key: 'UnsplashMediaTab',
        render: () => (
          <UnsplashMedia
            mediaOnPageLimit={mediaOnPageLimit}
            setMediaOnPageLimit={setMediaOnPageLimit}
            handleReloadMedia={handleReloadMedia}
          />
        ),
      },
    ];
  }, [
    t,
    mediaOnPageLimit,
    onUpload,
    selectedMedia,
    selectMedia,
    clearSelection,
    handleReloadMedia,
    testId,
  ]);

  const handleMediaSumSize = useMemo(() => {
    if (planLimits?.file_quota) {
      return (
        <div
          className={`ml-auto flex items-center justify-end w-auto px-2 sm:px-4
          ${planLimits?.file_quota > -1 ? 'md:w-[700px]' : 'md:w-[570px]'}`}
          {...getTestProps(testId, 'media-plan-usage')}
        >
          <Tooltip
            tooltip={t('Media.SpaceUsageTooltip')}
            tooltipPlacement="bottomCenter"
            phoneTooltipPlacement="bottomRight"
            additionalClasses="ml-2 md:!ml-0 mr-2 z-10"
            additionalTooltipClasses="rounded-tr mt-0"
          >
            <WarningIcon className="w-3.5 h-3.5 text-blue" />
          </Tooltip>
          <ProgressBar
            progressBarLabel={t('Media.SpaceUsage')}
            outOfCompletionValue={getFormatedSize(
              mediaSumSize,
              t('Global.Unlimited'),
              'file-size',
            )}
            outOfCompletionTotalValue={getFormatedSize(
              planLimits.file_quota,
              t('Global.Unlimited'),
              'file-size',
            )}
            completionPercentValue={
              planLimits.file_quota > 0
                ? (mediaSumSize / planLimits.file_quota) * 100
                : -1
            }
            barThickness={'thin'}
            hidePercentValue
            additionalLabelClasses={twMerge(
              'whitespace-nowrap !text-sm mb-0 mr-3',
            )}
            additionalCompletionValuesContainerClasses={twMerge(
              'mb-px mr-3 whitespace-nowrap leading-none',
            )}
            additionalContainerClasses={twMerge(
              '!text-sm md:!text-base grow w-auto flex-row flex-wrap sm:flex-nowrap items-center',
            )}
            additionalBarClasses="hidden sm:block"
          />

          <div className="flex items-center justify-end">
            {planLimits?.price !== -1 && isAdmin && (
              <LinkButton
                buttonSize="xs"
                additionalClasses={twMerge(
                  'whitespace-nowrap h-6 2xl:h-6 text-sm px-2 ml-2 md:ml-5',
                )}
                link={`/space/upgrade/${space}`}
              >
                {t('Global.UpgradePlan')}
              </LinkButton>
            )}
          </div>
        </div>
      );
    }
  }, [
    mediaSumSize,
    planLimits?.file_quota,
    planLimits?.price,
    space,
    testId,
    isAdmin,
    t,
  ]);

  return (
    <PageLayout
      page="media"
      title={t('Global.MediaLibrary')}
      breadcrumbs={<TopbarBreadcrumbs />}
      buttons={
        canCreate ? (
          <TopbarButton
            label={
              <>
                <input
                  ref={inputRef}
                  type="file"
                  className="hidden"
                  onChange={handleUpload}
                  accept={user?.data?.allowedMimetypes}
                  multiple
                  {...getTestProps(testId, 'global-file-input')}
                />
                {t('Media.AddFile')}
              </>
            }
            tooltip={t('Media.AddFileTooltip')}
            tooltipPlacement="leftBottom"
            additionalTooltipClasses={'hidden md:block'}
            as="label"
          />
        ) : null
      }
      secondaryTopbar={handleMediaSumSize}
      noPaddings
    >
      <Tabs
        additionalContainerClasses="order-2 lg:order-none px-5 lg:px-7 pt-5 md:pt-0"
        tabs={tabsContent}
      />
    </PageLayout>
  );
};

export default MediaLibrary;

MediaLibrary.propTypes = {
  /**
   * Test id for media library page
   */
  testId: PropTypes.string,
};

MediaLibrary.defaultProps = {
  testId: '',
};
