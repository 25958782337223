import { Fragment, useMemo, useState } from 'react';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useParams,
} from 'react-router-dom';

// :: Layout
import Layout from '../layout/Layout';
import LayoutSlim from '../layout/LayoutSlim';

// :: hoc
import WrapUserContext from '../hoc/WrapUserContext/WrapUserContext';

// :: Lib helpers
import { isModuleEnabled } from '../lib/helpers';

// :: Hoc
import NavigatePage from '../hoc/NavigatePage/NavigatePage';

// :: Pages
import AccountActivation from '../pages/AccountActivation/AccountActivation';
import AddContentObject from '../pages/AddContentObject/AddContentObject';
import AddContentTypeDefinition from '../pages/AddContentTypeDefinition/AddContentTypeDefinition';
import APIKeys from '../pages/APIKeys/APIKeys';
import ChangePasswordPage from '../pages/ChangePasswordPage/ChangePasswordPage';
import ChangePasswordRequest from '../pages/ChangePasswordRequest/ChangePasswordRequest';
import ContentTypeDefinitions from '../pages/ContentTypeDefinitions/ContentTypeDefinitions';
import ContentTypeObjects from '../pages/ContentTypeObjects/ContentTypeObjects';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import MediaLibrary from '../pages/MediaLibrary/MediaLibrary';
import Page404 from '../pages/Page404/Page404';
import RegisterAppSumo from '../pages/RegisterAppSumo/RegisterAppSumo';
import Register from '../pages/Register/Register';
import Users from '../pages/Users/Users';
import Starters from '../pages/Starters/Starters';
import AccountDeletion from '../pages/AccountDeletion/AccountDeletion';
import Profile from '../pages/Profile/Profile';
import AddWebhooks from '../pages/AddWebhooks/AddWebhooks';
import ContentEmpty from '../pages/ContentEmpty/ContentEmpty';
import UserRoles from '../pages/UserRoles/UserRoles';
import AddUserRole from '../pages/AddUserRole/AddUserRole';
import Logout from '../pages/Logout/Logout';
import Plans from '../pages/Plans/Plans';
import AddPlan from '../pages/AddPlan/AddPlan';
import Plugins from '../pages/Plugins/Plugins';
import EditMedia from '../pages/EditMedia/EditMedia';
import SpacesManage from '../pages/SpacesManage/SpacesManage';
import HomeSlim from '../pages/Home/HomeSlim';
import SpaceModify from '../pages/SpaceModify/SpaceModify';
import Spaces from '../pages/Spaces/Spaces';
import AddSpace from '../pages/AddSpace/AddSpace';
import DirtyHandlerContext from '../contexts/DirtyHandlerContext';
import Blocker from '../components/DirtyHandler/Blocker';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import ApiKeyAccess from '../pages/ApiKeyAccess/ApiKeyAccess';
import { ModalProvider } from '../contexts/ModalContext';

export const ParamKeyPage = ({ paramName, children }) => {
  const params = useParams();
  const key = params[paramName];
  return <Fragment key={key} children={children} />;
};

const PageWrapper = ({ children }) => {
  const [dirty, setDirty] = useState(false);

  const dirtyHandlerContextValue = useMemo(
    () => ({ dirty, setDirty }),
    [dirty],
  );

  return (
    <DirtyHandlerContext.Provider value={dirtyHandlerContextValue}>
      {children}
      <Blocker dirty={dirty} />
    </DirtyHandlerContext.Provider>
  );
};

export const webhooksDefinedColumns = () =>
  isModuleEnabled('WEBHOOKS_TYPE')
    ? ['name', 'type', 'url', 'enabled', 'actions']
    : ['name', 'url', 'enabled', 'actions'];

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<NavigatePage />}></Route>

        <Route path="/s/:spaceSlug/" element={<NavigatePage />}>
          <Route
            element={
              <PageWrapper>
                <WrapUserContext>
                  <Layout />
                </WrapUserContext>
              </PageWrapper>
            }
          >
            <Route path="" element={<Home />} />
            <Route
              path="content-type-objects/:contentTypeName"
              element={
                <ParamKeyPage paramName="contentTypeName">
                  <ContentTypeObjects />
                </ParamKeyPage>
              }
            />
            <Route
              path="content-type-objects/add/:contentTypeName"
              element={<AddContentObject mode={'add'} />}
            />
            <Route
              path="content-type-objects/edit/:contentTypeName/:id"
              element={<AddContentObject mode={'edit'} />}
            />
            <Route
              path="content-type-objects/duplicate/:contentTypeName/:id"
              element={<AddContentObject duplicate mode={'duplicate'} />}
            />
            <Route path="media" element={<MediaLibrary />} />
            <Route path="media/edit/:id" element={<EditMedia />} />
            <Route
              path="content-type-definitions"
              element={
                <ContentTypeDefinitions limit={1000} pagination={false} />
              }
            />
            <Route
              path="content-type-definitions/add"
              element={<AddContentTypeDefinition />}
            />
            <Route
              path="content-type-definitions/edit/:contentTypeName"
              element={<AddContentTypeDefinition />}
            />
            <Route
              path="content-type-definitions/duplicate/:contentTypeName"
              element={<AddContentTypeDefinition duplicate />}
            />
            <Route path="api-keys" element={<APIKeys />} />
            <Route path="plugins" element={<Plugins />} />
            <Route
              path="webhooks"
              element={
                <ContentTypeObjects
                  allowInternals
                  definedTypeName="_webhooks"
                  definedColumns={webhooksDefinedColumns()}
                />
              }
            />
            <Route path="webhooks/add" element={<AddWebhooks mode={'add'} />} />
            <Route
              path="webhooks/edit/:id"
              element={<AddWebhooks mode={'edit'} />}
            />
            <Route
              path="webhooks/duplicate/:id"
              element={<AddWebhooks mode={'edit'} duplicate />}
            />
            <Route path="user-roles" element={<UserRoles />} />
            <Route path="user-roles/add" element={<AddUserRole />} />
            <Route path="user-roles/edit/:id" element={<AddUserRole />} />
            <Route
              path="user-roles/duplicate/:id"
              element={<AddUserRole duplicate />}
            />
            <Route path="content" element={<ContentEmpty />} />
            <Route path="starters" element={<Starters />} />
          </Route>
        </Route>

        <Route element={<WrapUserContext />}>
          <Route
            element={
              <NavigatePage>
                <PageWrapper>
                  <LayoutSlim />
                </PageWrapper>
              </NavigatePage>
            }
          >
            <Route path="start" element={<HomeSlim />} />
          </Route>

          <Route
            element={
              <PageWrapper>
                <LayoutSlim template="settings" />
              </PageWrapper>
            }
          >
            <Route path="organization" element={<SpacesManage />} />
            <Route path="space/add" element={<SpaceModify mode="add" />} />
            <Route
              path="space/upgrade/:id"
              element={<SpaceModify mode="upgrade" />}
            />
            <Route
              path="space/edit/:id"
              element={<SpaceModify mode="edit" />}
            />

            <Route path="users" element={<Users />} />
            <Route path="users/add" element={<Profile mode="add" />} />
            <Route path="users/edit/:id" element={<Profile mode="edit" />} />
            <Route
              path="profile"
              element={<Profile owner={true} mode={'edit'} />}
            />
          </Route>
        </Route>

        <Route
          element={
            <WrapUserContext>
              <PageWrapper>
                <Layout />
              </PageWrapper>
            </WrapUserContext>
          }
        >
          <Route path="/spaces/edit/:id" element={<AddSpace />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/plans/add/" element={<AddPlan />} />
          <Route path="/plans/edit/:id" element={<AddPlan />} />

          <Route path="/users-data-preview" element={<Users isAllUsers />} />

          <Route path="/spaces-data-preview" element={<Spaces />} />
        </Route>
      </Route>

      <Route
        path="/login"
        element={
          <ProtectedRoute
            children={<Navigate to="/" replace />}
            redirect={<Login />}
          />
        }
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register/activate" element={<AccountActivation />} />
      <Route path="/delete-account-confirm" element={<AccountDeletion />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route
        path="/change-password-request"
        element={<ChangePasswordRequest />}
      />
      <Route path="/register-appsumo" element={<RegisterAppSumo />} />
      <Route path="*" element={<Page404 />} />

      <Route
        path="/access-api-key"
        element={
          <ModalProvider>
            <ApiKeyAccess />
          </ModalProvider>
        }
      />
    </>,
  ),
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
