import { useCallback, useEffect, useId, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import { getTestProps } from '../../lib/helpers';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import Button from '../Button/Button';
import { DeleteIcon } from '../../images/shapes';

const RadioGroup = ({
  options,
  value,
  name,
  onChange,
  onBlur,
  onFocus,
  horizontal,
  disabled,
  readonly,
  label,
  required,
  error,
  helpText,
  additionalClasses,
  additionalRadioErrorClasses,
  testId,
}) => {
  const componentId = useId();
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = useCallback(
    (value) => {
      if (disabled || readonly) return;
      setSelected(value);
      if (onChange) onChange({ target: { value, name } }, value);
    },
    [disabled, readonly, onChange, name],
  );

  const handleClear = useCallback(() => {
    handleChange('');
  }, [handleChange]);

  const handleBlur = useCallback(() => {
    onBlur({ target: { name } });
  }, [onBlur, name]);

  const renderOption = useCallback(
    (option, idx) => {
      const checked = option.value === selected;
      const keyValue = option.value + idx;
      return (
        <div
          className={twMerge(
            horizontal ? 'inline-flex items-center mr-4' : 'flex mb-2',
          )}
          key={keyValue}
          {...getTestProps(testId, `${option.value}-container`)}
        >
          <input
            type="radio"
            id={`${componentId}-${idx}`}
            name={name}
            value={option.value}
            onChange={() => handleChange(option.value)}
            onBlur={handleBlur}
            checked={checked}
            className="appearance-none"
            required={required}
            disabled={disabled}
            readOnly={readonly}
            onFocus={onFocus}
            {...getTestProps(testId, `${option.value}-input`)}
          />
          <label
            htmlFor={`${componentId}-${idx}`}
            className={twMerge(
              'text-sm inline-flex dark:text-white',
              disabled || readonly ? 'cursor-not-allowed' : 'cursor-pointer',
              idx === -1 && 'text-red',
            )}
            {...getTestProps(testId, `${option.value}-label`)}
          >
            <div
              className={twMerge(
                'relative h-[18px] w-[18px] border border-slate-200 dark:border-slate-800 rounded-full mr-2',
                disabled
                  ? 'bg-gray dark:bg-gray-700'
                  : 'bg-white dark:bg-transparent',
                idx === -1 && 'border-red',
              )}
            >
              {checked && (
                <span
                  className={twMerge(
                    'h-2.5 w-2.5 rounded-full absolute m-[3px]',
                    disabled ? 'bg-slate-400 dark:bg-gray-800' : 'bg-blue-600',
                    idx === -1 && '!bg-red',
                  )}
                  {...getTestProps(testId, 'checked')}
                />
              )}
            </div>
            {option.label}
          </label>
          {checked && !required && !disabled && !readonly && (
            <Button
              buttonSize="sm"
              buttonColor="borderless"
              iconImage={
                <DeleteIcon className="w-3 text-red hover:text-red-700" />
              }
              onClick={handleClear}
              additionalClasses="w-fit ml-1"
              noPaddings
              {...getTestProps(testId, 'clear-selection', 'testId')}
            />
          )}
        </div>
      );
    },
    [
      selected,
      horizontal,
      testId,
      componentId,
      name,
      handleBlur,
      required,
      disabled,
      readonly,
      onFocus,
      handleClear,
      handleChange,
    ],
  );

  return (
    <div className={twMerge(additionalClasses)}>
      {label && (
        <div
          className="text-sm text-slate-400 dark:text-gray-200 mb-1"
          {...getTestProps(testId, 'label')}
        >
          {label}
          {required && <RequiredTemplate testId={testId} />}
        </div>
      )}
      {options.map(renderOption)}
      {value &&
        !options.find((option) => option.value === value) &&
        renderOption({ value, label: value }, -1)}
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={error}
        additionalErrorClasses={additionalRadioErrorClasses}
        testId={testId}
      />
    </div>
  );
};

export default RadioGroup;

RadioGroup.propTypes = {
  /**
   * List of options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  /**
   * Selected value
   */
  value: PropTypes.any,
  /**
   * If radio group is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If field is readonly (non interactive but without disabled classes)
   */
  readonly: PropTypes.bool,
  /**
   * If radio group is required
   */
  required: PropTypes.bool,
  /**
   * Radio group name
   */
  name: PropTypes.string,
  /**
   * On change callback
   */
  onChange: PropTypes.func,
  /**
   * Radio on blur handler
   */
  onBlur: PropTypes.func,
  /**
   * Radio on focus handler
   */
  onFocus: PropTypes.func,
  /**
   * If radio group should be shown horizontally
   */
  horizontal: PropTypes.bool,
  /**
   * Label for radio group
   */
  label: PropTypes.node,
  /**
   * Radio group text that will inform about error
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Help text under radio group
   */
  helpText: PropTypes.node,
  /**
   * Radio additional container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Radio additional error classes
   */
  additionalRadioErrorClasses: PropTypes.string,
  /**
   * Test id for radio group
   */
  testId: PropTypes.string,
};

RadioGroup.defaultProps = {
  value: null,
  name: '',
  disabled: false,
  readonly: false,
  horizontal: false,
  label: '',
  required: false,
  error: '',
  helpText: '',
  additionalClasses: '',
  additionalRadioErrorClasses: '',
  testId: '',
  onBlur: /* istanbul ignore next */ () => null,
};
