import { useContext, useCallback, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import {
  useContentObject,
  useContentType,
  usePluginsSettings,
} from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import usePluginResults from '../../hooks/usePluginResults';
import UserContext from '../../contexts/UserContext';
import { useModals } from '../../contexts/ModalContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';
import { ClipboardIcon, WarningIcon } from '../../images/shapes';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { getTestProps } from '../../lib/helpers';
import { FormAddSidebarPanelEvent } from '../../lib/flotiq-plugins/plugin-events/FormAddSidebarPanelEvent';
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import ContentObjectBacklinks from '../AddContentObject/ContentObjectBacklinks/ContentObjectBacklinks';
import MediaForm from '../../form/MediaForm/MediaForm';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import useFirstLoading from '../../hooks/useFirstLoading';
import ElementFromPlugin from '../../components/ElementFromPlugin/ElementFromPlugin';
import { useBacklinks } from '../../hooks/api/useBacklinks';
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarCancelButton from '../../components/Topbar/buttons/TopbarCancelButton';
import TopbarSaveButton from '../../components/Topbar/buttons/TopbarSaveButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import TopbarSaveAndLeaveButton from '../../components/Topbar/buttons/TopbarSaveAndLeaveButton';
import TopbarDeleteButton from '../../components/Topbar/buttons/TopbarDeleteButton';

const USER_PLUGINS_PARAMS = {
  limit: 1000,
  page: 1,
};

const EditMedia = ({ testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateOnSave = useRef();
  const modal = useModals();
  const { permissions } = useContext(UserContext);
  const { buildUrlWithSpace } = useSelectedSpace();
  const { setDirty } = useContext(DirtyHandlerContext);

  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [formikState, setFormikState] = useState();

  const { data: userPlugins } = usePluginsSettings(USER_PLUGINS_PARAMS);
  const { entity: mediaContentType } = useContentType('_media');

  const {
    entity: media,
    isLoading,
    status,
    errors,
    deleteEntity: deleteMediaObject,
    updateEntity: updateMediaObject,
  } = useContentObject('_media', id);

  const firstLoading = useFirstLoading(!isLoading || !id, id);

  useApiErrorsToast(errors);

  const handleDeleteMedia = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteMediaObject({
        contentTypeName: '_media',
      });
      checkResponseStatus(body, status);

      toast.success(t('Media.MediaManagement.DeleteSuccess'));
      setDirty(false);
      navigate(buildUrlWithSpace('media'));
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message
            ? error.message
            : t('Media.MediaManagement.CouldntDelete'),
        );
      }
    }
  }, [modal, deleteMediaObject, t, setDirty, navigate, buildUrlWithSpace]);

  const deleteMedia = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(
      <div className="dark:text-white">
        <Heading additionalClasses="dark:text-white" level={6}>
          {t('MediaEdit.ConfirmDelete')}
        </Heading>
        <p>{t('Media.MediaManagement.DeleteWarning')}</p>
      </div>,
      'delete-modal',
      () => handleDeleteMedia(),
    );
    setIsDeleting(false);
  }, [handleDeleteMedia, modal, t]);

  const { canDelete, canUpdate, canRead } = useMemo(
    () => permissions.getCoPermissions('_media') || {},
    [permissions],
  );

  const {
    backlinks,
    backlinkContentTypes,
    backlinkPagination,
    setBacklinksPage,
  } = useBacklinks(id, '_media');

  const updateMedia = useCallback(
    async (values) => {
      setIsSaving(true);
      try {
        const { status, body } = await updateMediaObject({
          contentTypeName: '_media',
          ...values,
        });
        checkResponseStatus(body, status);
        toast.success(t('MediaEdit.Updated'));

        return [[body, {}], false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [[values, {}], true];
        }
        toast.error(error.message || t('MediaEdit.CouldntUpdate'));
        return [[values, error.errors], true];
      } finally {
        setIsSaving(false);
      }
    },
    [updateMediaObject, t],
  );

  const showForm = id && media && !firstLoading && canRead;

  const emptyData = useMemo(() => {
    if (showForm) return null;
    if (firstLoading) {
      return (
        <Loader
          size={'small'}
          type={'spinner-grid'}
          testId={testId ? `${testId}-loader` : ''}
        />
      );
    }
    if (!canRead) {
      toast.error(t('MediaEdit.CouldntFind', { id }));
    }
    return (
      <Heading
        level={2}
        additionalClasses={twMerge(
          'text-3xl md:text-4xl leading-8 dark:text-white',
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('MediaEdit.CouldntFind', { id })}
          />
          {status === 404 || !canRead
            ? t('MediaEdit.CouldntFind', { id })
            : t('MediaEdit.CouldntFetch')}
        </div>
      </Heading>
    );
  }, [showForm, firstLoading, canRead, testId, t, id, status]);

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
   */
  const pluginSidebarPanels = usePluginResults(
    'flotiq.form.sidebar-panel::add',
    FormAddSidebarPanelEvent,
    {
      contentType: mediaContentType,
      contentObject: media,
      disabled: isSaving || isDeleting,
      duplicate: false,
      create: false,
      userPlugins,
      formik: formikState,
    },
  );

  const contentObjectFormContextValue = useMemo(
    () => ({
      contentType: mediaContentType,
      initialData: media,
      userPlugins: userPlugins,
    }),
    [media, mediaContentType, userPlugins],
  );

  const isSaveButton = canUpdate && id;

  return (
    <PageLayout
      page="media"
      title={t('MediaEdit.Edit')}
      buttonsDisabled={isSaving || isDeleting}
      breadcrumbs={
        <TopbarBreadcrumbs
          parentTitle={t('Global.MediaLibrary')}
          parentLink={buildUrlWithSpace('media')}
        />
      }
      buttons={
        <>
          <TopbarCancelButton link={buildUrlWithSpace('media')} />
          {isSaveButton && (
            <TopbarSaveButton
              form="media-form"
              isLoading={isSaving}
              navigateOnSave={navigateOnSave}
            />
          )}
          <TopbarActionMenu>
            {isSaveButton && (
              <TopbarSaveAndLeaveButton
                form="media-form"
                navigateOnSave={navigateOnSave}
              />
            )}
            {canDelete && id && <TopbarDeleteButton onClick={deleteMedia} />}
          </TopbarActionMenu>
        </>
      }
      testId={testId}
    >
      {showForm ? (
        <div className={predefinedLayoutClasses.withSidebar}>
          <div
            className={twMerge(
              predefinedLayoutClasses.leftColumn,
              'space-y-3 md:space-y-6',
            )}
          >
            <ContentObjectFormContext.Provider
              value={contentObjectFormContextValue}
            >
              <MediaForm
                media={media}
                contentType={mediaContentType}
                onSubmit={updateMedia}
                navigateOnSave={navigateOnSave}
                disabled={isSaving || isDeleting || !canUpdate}
                setFormikState={setFormikState}
                {...getTestProps(testId, 'form', 'testId')}
              />
            </ContentObjectFormContext.Provider>
          </div>
          <div className={predefinedLayoutClasses.rightColumn}>
            <ContentObjectInformations
              id={media.id}
              createdAt={media.internal?.createdAt}
              updatedAt={media.internal?.updatedAt}
              additionalElements={
                <div
                  className={twMerge(
                    'flex items-start text-indigo-950 dark:text-gray-200',
                  )}
                >
                  <ClipboardIcon className="min-w-4 w-4 mr-3" />
                  <span>ID</span>
                  <span className="font-bold mx-1 break-all">{id}</span>
                </div>
              }
              additionalClasses="order-10"
              {...getTestProps(testId, 'info', 'testId')}
            />

            {backlinks?.length > 0 && (
              <ContentObjectBacklinks
                backlinks={backlinks}
                contentTypes={backlinkContentTypes}
                pagination={backlinkPagination}
                onPageChange={setBacklinksPage}
                additionalClasses="order-20"
                {...getTestProps(testId, 'backlinks', 'testId')}
              />
            )}

            {pluginSidebarPanels?.length > 0 && (
              <ElementFromPlugin results={pluginSidebarPanels} />
            )}
          </div>
        </div>
      ) : (
        <div className={predefinedLayoutClasses.whiteBox}>{emptyData}</div>
      )}
    </PageLayout>
  );
};

export default EditMedia;

EditMedia.propTypes = {
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

EditMedia.defaultProps = {
  testId: '',
};
