import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import Topbar from '../../components/Topbar/Topbar';
import { FlotiqBadgeLogo } from '../../images/shapes';
import { Helmet } from 'react-helmet';
import { useContext, useEffect } from 'react';
import AppContext from '../../contexts/AppContext';
import { getTestProps } from '../../lib/helpers';

const leftColumnClasses = 'xl:col-span-5';

export const predefinedLayoutClasses = {
  withSidebar: 'grid grid-cols-1 xl:grid-cols-7 gap-4 lg:gap-7 h-full',
  leftColumn: leftColumnClasses,
  leftColumnWhite: leftColumnClasses + ' bg-white dark:bg-slate-950 rounded-lg',
  rightColumn:
    'pt-4 xl:pt-0 border-t xl:border-t-0 dark:border-slate-800 xl:col-span-2 flex flex-col gap-5',
  whiteBox:
    'flex flex-col items-center justify-center h-full bg-white dark:bg-slate-950 rounded-lg p-4',
  withDataGrid:
    'w-full h-[calc(100vh-200px)] lg:h-[calc(100vh-160px)] px-5 lg:px-7',
  dataGridContainer:
    'relative w-full h-full min-h-0 bg-white dark:bg-slate-950 w-full h-full rounded-lg mt-4 lg:mt-7',
};

const PageLayout = ({
  title,
  breadcrumbs,
  buttons,
  buttonsDisabled,
  children,
  id,
  page,
  menuItemOpen,
  secondaryTopbar,
  status,
  noPaddings,
  testId,
}) => {
  const { updateAppContext } = useContext(AppContext);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      id: id || page,
      ...(page ? { page } : {}),
      ...(menuItemOpen ? { menuItemOpen } : {}),
    }));
  }, [id, menuItemOpen, page, updateAppContext]);

  return (
    <div className="relative h-full w-full">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Topbar
        topbarLogo={<FlotiqBadgeLogo className="h-10" />}
        topbarHeading={title || ''}
        buttons={buttons}
        buttonsDisabled={buttonsDisabled}
        status={status}
        additionalClasses={twMerge(
          'sticky top-layoutOffset z-[19] w-full',
          'transition-all',
          'ease-in-out',
          'duration-normal',
        )}
        breadcrumbs={breadcrumbs}
        {...getTestProps(testId, 'topbar', 'testId')}
      />
      <div className="flex items-stretch w-full min-h-[calc(100vh-71px)]">
        <div className="flex flex-col w-full">
          {secondaryTopbar && (
            <div
              className={twMerge(
                'flex flex-wrap sm:flex-nowrap items-center justify-between relative',
                'px-3 lg:px-7 py-2 min-h-8 h-auto sm:h-11 w-full z-10 bg-slate-50 border-b gap-3 sm:gap-0',
                'dark:bg-gray-900 dark:border-b dark:border-slate-800',
              )}
            >
              {secondaryTopbar}
            </div>
          )}
          <div
            className={twMerge('h-full relative', !noPaddings && 'p-4 lg:p-7 ')}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;

PageLayout.propTypes = {
  /**
   * Page title shown in helmet and topbar
   */
  title: PropTypes.string.isRequired,
  /**
   * Topbar breadcrumbs
   */
  breadcrumbs: PropTypes.node,
  /**
   * Topbar buttons
   */
  buttons: PropTypes.node,
  /**
   * If topbar buttons are disabled
   */
  buttonsDisabled: PropTypes.bool,
  /**
   * Page id
   */
  id: PropTypes.string,
  /**
   * Page sidebar key
   */
  page: PropTypes.string,
  /**
   * Sidebar menu items that should be opened
   */
  menuItemOpen: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

PageLayout.defaultProps = {
  buttonsDisabled: false,
  testId: '',
};
