import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Hooks
import {
  useContentTypes,
  useMarketplacePlugins,
  usePluginsSettings,
} from '../../hooks/api';
import useToken from '../../hooks/useToken';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useUpdatePluginVersion from './updatePluginVersionHook';
import useSelectedSpace from '../../hooks/useSelectedSpace';

// :: Context
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import { getTestProps, sendEventAndClear } from '../../lib/helpers';
import {
  deleteContentObject,
  patchContentObject,
  postContentObject,
} from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import {
  defaultResponseParser,
  fetchMethod,
} from '../../lib/flotiq-client/base-request';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import { PluginSettingsChangedEvent } from '../../lib/flotiq-plugins/plugin-events/PluginSettingsChangedEvent';

// :: Images

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import ManageModal from './ManageModal/ManageModal';
import PluginItem from './PluginItem/PluginItem';
import CustomUIPluginForm from '../../form/CustomUIPluginForm/CustomUIPluginForm';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import LinkButton from '../../components/LinkButton/LinkButton';
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarButton from '../../components/Topbar/buttons/base/TopbarButton';
import { useSpaceLimits } from '../../hooks/useSpaceLimits';

const HOOKS_PARAMS = {
  limit: 1000,
  page: 1,
};

const checkIfHostAllowed = (allowedHosts, pluginUrl, t) => {
  if (!allowedHosts.includes('*')) {
    let url = '';
    try {
      url = new URL(pluginUrl);
    } catch (e) {
      throw new ResponseError(t('Plugins.WrongURL'));
    }

    if (!allowedHosts.includes(url.hostname)) {
      throw new ResponseError(t('Plugins.HostNotAllowed'));
    }
  }
};

const getPluginType = (pluginId, marketplaceIds) => {
  if (!pluginId || !marketplaceIds) return 'custom';
  return marketplaceIds.includes(pluginId) ? 'official' : 'custom';
};

const RESOURCE_NAMES = ['official_plugins_limit', 'custom_plugins_limit'];

const Plugins = ({ testId }) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const { baseUserEventData, planLimits, isAdmin } = useContext(UserContext);

  const modal = useModals();
  const updatePluginVersion = useUpdatePluginVersion();

  const {
    data: marketplace,
    isLoading: marketplaceLoading,
    errors,
  } = useMarketplacePlugins();

  useEffect(() => {
    if (!errors || !errors.length > 0) return;
    toast.error(t('Plugins.FetchError'));
  }, [errors, t]);

  const { data: resources, isLoading: constraintsLoading } = useSpaceLimits(
    planLimits,
    RESOURCE_NAMES,
  );

  const allowedHosts = useMemo(
    () => planLimits?.allowed_hosts_for_custom_plugins?.split(','),
    [planLimits?.allowed_hosts_for_custom_plugins],
  );

  const marketplaceIds = useMemo(
    () => marketplace?.map(({ id }) => id),
    [marketplace],
  );

  const [isSaving, setIsSaving] = useState(false);

  const {
    data: contentTypes,
    isLoading: contentTypesLoading,
    errors: contentTypesErrors,
  } = useContentTypes(HOOKS_PARAMS);

  const {
    data: userPlugins,
    isLoading: userPluginsAreLoading,
    errors: userPluginsErrors,
    reload: reloadUserPlugins,
  } = usePluginsSettings(HOOKS_PARAMS);

  useApiErrorsToast(userPluginsErrors);
  useApiErrorsToast(contentTypesErrors);

  const enabledPluginsIds = useMemo(
    () => userPlugins.map(({ id }) => id),
    [userPlugins],
  );

  const addToPlugins = useCallback(
    async (pluginData, enabled = true) => {
      try {
        const { body, status } = await postContentObject(jwt, space, {
          contentTypeName: '_plugin_settings',
          id: pluginData.id,
          name: pluginData.name,
          url: pluginData.url,
          version: pluginData.version,
          description: pluginData.description,
          manifest: JSON.stringify(pluginData),
          enabled,
        });

        checkResponseStatus(body, status);
        return [body, null];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message ||
              t('Plugins.UpdatingError', { pluginName: pluginData.name }),
          );
        }
        return [null, error];
      }
    },
    [jwt, space, t],
  );

  const updatePlugin = useCallback(
    async (id, pluginName, enabled, settings) => {
      try {
        const { body, status } = await patchContentObject(jwt, space, {
          contentTypeName: '_plugin_settings',
          id,
          name: pluginName,
          enabled,
          ...(settings ? { settings } : {}),
        });

        checkResponseStatus(body, status);

        if (settings) {
          FlotiqPlugins.setPluginSettings(id, body.settings);
          FlotiqPlugins.runScoped(
            'flotiq.plugin.settings::changed',
            id,
            new PluginSettingsChangedEvent({ settings: body.settings }),
          );
        }

        return [body, {}];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [null, { global: 'Form.CommunicationErrorMessage' }];
        }

        toast.error(
          error.message || t('Plugins.UpdatingError', { pluginName }),
        );
        return [null, error.errors];
      }
    },
    [jwt, space, t],
  );

  const enablePlugin = useCallback(
    async (pluginData, isNew = true, enabled = true) => {
      setIsSaving(true);
      let result;

      if (isNew) {
        result = await addToPlugins(pluginData);
      } else {
        result = await updatePlugin(pluginData.id, pluginData.name, enabled);
      }

      setIsSaving(false);

      const [newPlugin] = result;
      if (newPlugin) {
        window.location.reload();

        if (!isNew)
          sendEventAndClear(
            {
              event: 'plugin_updated',
              plugin_id: newPlugin.id,
              plugin_type: getPluginType(newPlugin.id, marketplaceIds),
              enabled,
            },
            baseUserEventData,
          );
        else
          sendEventAndClear(
            {
              event: 'plugin_added',
              plugin_id: newPlugin.id,
              plugin_type: 'official',
            },
            baseUserEventData,
          );
      }

      return newPlugin ? newPlugin.enabled : !enabled;
    },
    [addToPlugins, baseUserEventData, marketplaceIds, updatePlugin],
  );

  const openManageModal = useCallback(
    async (userPlugin) => {
      await modal({
        title: t('Plugins.ManageTitle', { pluginName: userPlugin.name }),
        content: (
          <ManageModal
            plugin={userPlugin}
            userPlugins={userPlugins}
            contentTypes={contentTypes}
            updatePlugin={updatePlugin}
            reloadUserPlugins={reloadUserPlugins}
            {...getTestProps(testId, userPlugin.id, 'testId')}
          />
        ),
        size: '2xl',
        dialogAdditionalClasses: '!max-h-[calc(100vh-3rem)] overflow-visible',
        contentAdditionalClasses: 'min-h-64',
      });
    },
    [
      modal,
      t,
      userPlugins,
      contentTypes,
      updatePlugin,
      reloadUserPlugins,
      testId,
    ],
  );

  const addUIPlugin = useCallback(
    async ({ url }) => {
      if (!url) return;
      setIsSaving(true);

      try {
        const pluginInfoResponse = await fetchMethod(url);
        const { status, body } = await defaultResponseParser(
          pluginInfoResponse,
        );

        checkResponseStatus(body, status);

        if (marketplaceIds.includes(body.id)) {
          throw new ResponseError(t('Plugins.ExistingId'));
        }
        checkIfHostAllowed(allowedHosts, body.url, t);

        const userPlugin = userPlugins?.find(({ id }) => id === body.id);
        if (userPlugin) {
          await updatePluginVersion(
            userPlugin,
            body,
            getPluginType(userPlugin, marketplaceIds),
          );

          setIsSaving(false);
          return;
        }

        const [newPlugin] = await addToPlugins(body, false);

        if (newPlugin) {
          sendEventAndClear(
            {
              event: 'plugin_added',
              plugin_id: newPlugin.id,
              plugin_type: 'custom',
            },
            baseUserEventData,
          );
        }

        reloadUserPlugins();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(error.message || t('Plugins.InfoFetchError'));
        }
      }

      setIsSaving(false);
    },
    [
      marketplaceIds,
      allowedHosts,
      t,
      userPlugins,
      addToPlugins,
      reloadUserPlugins,
      updatePluginVersion,
      baseUserEventData,
    ],
  );

  const removePlugin = useCallback(
    async (plugin) => {
      setIsSaving(true);

      try {
        const { body, status } = await deleteContentObject(jwt, space, {
          contentTypeName: '_plugin_settings',
          id: plugin.id,
        });

        checkResponseStatus(body, status);

        sendEventAndClear(
          {
            event: 'plugin_removed',
            plugin_id: plugin.id,
            plugin_type: getPluginType(plugin.id, marketplaceIds),
          },
          baseUserEventData,
        );

        await Promise.all(
          FlotiqPlugins.runScoped(
            'flotiq.plugin::removed',
            plugin.id,
            new PluginSettingsChangedEvent(),
          ),
        );

        window.location.reload();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message ||
              t('Plugins.DeletingError', { pluginName: plugin.name }),
          );
        }
      }

      setIsSaving(false);
    },
    [baseUserEventData, jwt, space, marketplaceIds, t],
  );

  const onVersionUpdate = useCallback(
    async (plugin, newPluginVersion) => {
      setIsSaving(true);

      await updatePluginVersion(
        plugin,
        newPluginVersion,
        getPluginType(plugin, marketplaceIds),
      );

      setIsSaving(false);
    },
    [marketplaceIds, updatePluginVersion],
  );

  const pluginLimits = useMemo(() => {
    return resources.map((resource) => (
      <ProgressBar
        key={resource.key}
        progressBarLabel={resource.label}
        completionPercentValue={resource.percent}
        outOfCompletionTotalValue={resource.limitLabel}
        outOfCompletionValue={resource.value}
        barThickness="thin"
        additionalCompletionValuesContainerClasses="whitespace-nowrap"
        hidePercentValue
      />
    ));
  }, [resources]);

  const customLimitInfo = useMemo(
    () =>
      resources[1].limit > 0 && resources[1].percent >= 100
        ? t('Plugins.CustomLimitReached')
        : '',
    [resources, t],
  );
  const officialLimitInfo = useMemo(
    () =>
      resources[0].limit > 0 && resources[0].percent >= 100
        ? t('Plugins.OfficialLimitReached')
        : '',
    [resources, t],
  );

  return (
    <PageLayout
      page="plugins"
      title={t('Global.Plugins')}
      breadcrumbs={<TopbarBreadcrumbs />}
      buttons={
        <TopbarButton
          label={t('Global.Documentation')}
          link={process.env.REACT_APP_PLUGINS}
          target="_blank"
          rel="noreferrer"
        />
      }
    >
      <div className={predefinedLayoutClasses.withSidebar}>
        <div className={predefinedLayoutClasses.leftColumn}>
          {marketplaceLoading ||
          userPluginsAreLoading ||
          contentTypesLoading ||
          constraintsLoading ? (
            <div className="h-full overflow-hidden flex justify-center items-center">
              <Loader
                type="spinner-grid"
                size="big"
                {...getTestProps(testId, 'loader', 'testId')}
              />
            </div>
          ) : (
            <>
              {(marketplace?.length > 0 || userPlugins?.length > 0) && (
                <>
                  <div className="flex flex-col md:flex-row gap-2 md:items-center lg:mb-4">
                    <Heading
                      level={3}
                      additionalClasses={
                        'pt-0 pb-0 mb-2 md:mb-0 text-xl md:text-3xl leading-none dark:text-white w-fit'
                      }
                    >
                      Flotiq Plugins
                    </Heading>
                    {isSaving && <Loader type="spinner-grid" size="tiny" />}
                    <div
                      className={twMerge(
                        'py-2 px-4 flex bg-white dark:bg-slate-950 items-start',
                        'items-center justify-between rounded-lg grow space-x-2 xs:space-x-6',
                        'md:max-w-md lg:hidden md:ml-auto my-2 -order-1 md:order-none',
                      )}
                    >
                      {pluginLimits}
                    </div>
                  </div>

                  {allowedHosts?.length > 0 && (
                    <CustomUIPluginForm
                      onSubmit={addUIPlugin}
                      disabled={isSaving}
                      allowedHosts={allowedHosts}
                      testId={testId}
                    />
                  )}

                  <div className="flex flex-col">
                    {userPlugins?.map((plugin) => {
                      const libraryPlugin = marketplace?.find(
                        (userPlugin) => userPlugin.id === plugin.id,
                      );

                      const newPluginVersion =
                        libraryPlugin &&
                        libraryPlugin?.version !== plugin.version
                          ? libraryPlugin
                          : null;

                      const manifest = plugin.manifest
                        ? JSON.parse(plugin.manifest)
                        : {};

                      return (
                        <PluginItem
                          key={plugin.id}
                          plugin={plugin}
                          manifest={manifest}
                          newPluginVersion={newPluginVersion}
                          openManageModal={openManageModal}
                          enablePlugin={enablePlugin}
                          disabled={isSaving}
                          onDelete={removePlugin}
                          onUpdate={onVersionUpdate}
                          limitReachedInfo={
                            libraryPlugin ? officialLimitInfo : customLimitInfo
                          }
                          {...getTestProps(testId, plugin.id, 'testId')}
                        />
                      );
                    })}

                    {marketplace
                      ?.filter(({ id }) => !enabledPluginsIds.includes(id))
                      .map((plugin) => (
                        <PluginItem
                          key={plugin.id}
                          plugin={plugin}
                          manifest={plugin}
                          openManageModal={openManageModal}
                          enablePlugin={enablePlugin}
                          onDelete={removePlugin}
                          disabled={isSaving}
                          limitReachedInfo={officialLimitInfo}
                          isNew
                          {...getTestProps(testId, plugin.id, 'testId')}
                        />
                      ))}
                  </div>
                  <div className="mt-5 border-t xl:border-0 dark:border-slate-800 h-1 w-full" />
                </>
              )}
            </>
          )}
        </div>
        <div className={predefinedLayoutClasses.rightColumn}>
          <div className="hidden lg:block px-7 py-5 rounded-lg bg-white dark:bg-slate-950 relative h-fit">
            <div
              className={
                'w-full flex flex-wrap gap-x-2 gap-y-1 items-center justify-between ' +
                'mr-2 font-bold text-base dark:text-white'
              }
            >
              {t('Plugins.LimitDetails')}
              {isAdmin && planLimits?.price !== -1 && (
                <LinkButton buttonSize="xs" link={`/space/upgrade/${space}`}>
                  {t('Global.UpgradePlan')}
                </LinkButton>
              )}
            </div>
            <div className="flex flex-col 2xl:flex-row gap-6 pt-4 dark:text-white">
              {pluginLimits}
            </div>
          </div>
          <div className="px-7 py-5 rounded-lg bg-white dark:bg-slate-950 relative h-fit">
            <div className="font-bold text-base dark:text-white">
              {t('Plugins.About')}
            </div>
            <div className="pt-4 dark:text-gray-200">
              <Trans
                i18nKey="Plugins.AboutDescription"
                components={{
                  1: (
                    <a
                      className="font-bold"
                      href={process.env.REACT_APP_PLUGINS}
                      target="_blank"
                      rel="noreferrer"
                    >
                      see documentation
                    </a>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Plugins;

Plugins.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Plugins.defaultProps = {
  testId: '',
};
