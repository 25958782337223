import { useQuery, useQueryClient } from '@tanstack/react-query';
import useSelectedSpace from '../useSelectedSpace';
import useToken from '../useToken';
import { useCallback, useMemo } from 'react';
import { getAllConstraints, getConstraints } from '../../lib/flotiq-client';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import { getQueryCacheKey } from '../../lib/helpers';

const DEFAULT_OPTIONS = {};

const allConstraintsMap = {
  'content-objects-count': 'cto-count',
  'content-definitions-count': 'ctd-count',
};

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export const useAllSpaceConstraints = (space, options = DEFAULT_OPTIONS) => {
  const queryClient = useQueryClient();
  const jwt = useToken();
  const { space: currentSpace } = useSelectedSpace();

  const {
    checkStatus = true,
    cancelUnfinishedRequests = true,
    pause = false,
    staleTime = CACHE_TIME,
    gcTime = CACHE_TIME * 2,
  } = options || {};

  const finalSpace = useMemo(
    () => space || currentSpace,
    [currentSpace, space],
  );

  const params = useMemo(
    () => ({
      space: finalSpace,
    }),
    [finalSpace],
  );

  const queryKey = useMemo(
    () => getQueryCacheKey('constraints', 'all', params, 'entity'),
    [params],
  );

  const {
    isLoading: requestLoading,
    data: response,
    error: allConstraintsErrors,
    refetch: reloadAllConstraints,
    isPlaceholderData,
  } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const result = await getAllConstraints(
        jwt,
        undefined,
        params,
        cancelUnfinishedRequests ? { signal } : {},
      );
      if (checkStatus) checkResponseStatus(result.body, result.status);
      return result;
    },
    enabled: !!jwt && !pause && !!finalSpace,
    staleTime,
    gcTime,
    retry: 0,
  });

  const isLoading = requestLoading || isPlaceholderData;

  const allConstraints = useMemo(
    () => response?.body || null,
    [response?.body],
  );

  const reloadSelectedConstraints = useCallback(
    async (names) => {
      const newCounts = {};
      return Promise.all(
        names.map((name) =>
          getConstraints(jwt, undefined, {
            name: allConstraintsMap[name] || name,
            space: finalSpace,
          })
            .then(({ body: constraintBody, status }) => {
              checkResponseStatus(constraintBody, status);
              newCounts[name] = constraintBody.data;
            })
            .catch((e) => {
              console.error(e);
            }),
        ),
      )
        .then(() =>
          queryClient.setQueryData(queryKey, (oldData) => ({
            ...oldData,
            body: { ...oldData?.body, ...newCounts },
          })),
        )
        .catch((e) => console.error(e));
    },
    [queryKey, finalSpace, jwt, queryClient],
  );

  const reload = useCallback(
    async (constraintNames) => {
      if (!constraintNames?.length) return reloadAllConstraints();
      return reloadSelectedConstraints(constraintNames);
    },
    [reloadAllConstraints, reloadSelectedConstraints],
  );

  const result = [allConstraints, isLoading, allConstraintsErrors, reload];
  Object.assign(result, {
    allConstraints,
    isLoading,
    errors: allConstraintsErrors,
    reload,
  });
  return result;
};
