import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import LinkButton from '../../LinkButton/LinkButton';

const colorClasses = {
  black:
    'text-default hover:text-default active:text-default focus:text-default',
  blue: 'text-blue hover:text-blue active:text-blue focus:text-blue',
  red: 'text-red-600 hover:text-red-600 dark:text-red-400',
  green: 'text-lime-700 hover:text-lime-700 dark:text-lime-300',
  orange: 'text-amber-500 hover:text-orange-700 dark:text-orange-700',
};

export const baseUIPopoverItemClasses =
  'h-9 2xl:h-10 px-6 rounded-none w-full justify-end text-base 2xl:text-base' +
  ' hover:underline hover:bg-gradient-menu-hover';

const PopoverItem = ({
  icon,
  color,
  link,
  label,
  disabled,
  additionalClasses,
  additionalIconClasses,
  ...props
}) => {
  const Component = link ? LinkButton : Button;
  return (
    <Component
      buttonColor="borderless"
      buttonSize="xs"
      iconPosition="start"
      iconImage={icon}
      additionalClasses={twMerge(
        baseUIPopoverItemClasses,
        !disabled && 'cursor-pointer',
        colorClasses[color] || colorClasses.black,
        additionalClasses,
      )}
      additionalIconClasses={twMerge('mr-2', additionalIconClasses)}
      disabled={disabled}
      {...(link ? { link } : {})}
      {...props}
    >
      {label}
    </Component>
  );
};

export default PopoverItem;

PopoverItem.propTypes = {
  /**
   * Popover icon
   */
  icon: PropTypes.node,
  /**
   * Button text color
   */
  color: PropTypes.string,
  /**
   * Item link
   */
  link: PropTypes.string,
  /**
   * Button label
   */
  label: PropTypes.node,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Additional popover button classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Additional popover button icon classes
   */
  additionalIconClasses: PropTypes.string,
};

PopoverItem.defaultProps = {
  color: 'black',
  label: '',
  disabled: false,
  link: '',
  additionalClasses: '',
  additionalIconClasses: '',
};
