import { useQuery } from '@tanstack/react-query';
import useToken from '../../hooks/useToken';
import { getUser } from '../../lib/flotiq-client';
import useLocalStorageState from 'use-local-storage-state';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';

export const useLoggedUser = () => {
  const jwt = useToken();
  const [user] = useLocalStorageState('cms.user');
  const userId = user?.data?.id;

  const { isLoading, data: response } = useQuery({
    queryKey: ['logged-users', { id: userId }],
    queryFn: async ({ signal }) => {
      const result = await getUser(jwt, undefined, { id: userId }, { signal });
      checkResponseStatus(result.body, result.status);
      return Promise.resolve(result);
    },
    enabled: !!jwt && !!userId,
    staleTime: Infinity,
    gcTime: 0,
    retry: 0,
  });

  return { isLoading, user: response?.body };
};
