import { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import { twMerge } from 'tailwind-merge';

// :: Lib
import {
  getCtdFeaturedImage,
  getMediaUrl,
} from '../../lib/flotiq-client/api-helpers';
import { getTestProps, isImagePreviewSupported } from '../../lib/helpers';

// :: Components
import Card from '../Card/Card';
import Tooltip from '../Tooltip/Tooltip';
import Button from '../Button/Button';
import VariantsGrid from './VariantsGrid/VariantsGrid';

// :: Images
import {
  DeleteIcon,
  PencilIcon,
  LinkIcon,
  ArrowUpRightIcon,
  ArrowUpThinIcon,
} from '../../images/shapes';

const RelationCard = forwardRef(
  (
    {
      relation,
      relationType,
      titleField,
      featuredImageUrl,
      featuredImageLoading,
      relationsAreLoading,
      onClick,
      imageOverlay,
      thumbnail,
      relationsLength,
      onDelete,
      onUp,
      onDown,
      onEdit,
      onShow,
      disabled,
      idx,
      onVariantCreate,
      onVariantDelete,
      dragElement,
      additionalContainerClasses,
      testId,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const isImage = relation.type === 'image';
    const isMedia = relationType === '_media';

    const title =
      relation.internal?.objectTitle || relation[titleField] || relation.id;

    const imageTitleField = isMedia ? relation.title : '';

    const imagePreviewSupported = isImagePreviewSupported(relation.extension);
    const getMediaUrlOnSuported = imagePreviewSupported
      ? getMediaUrl(relation, 280)
      : '';

    const mediaUrl = isImage ? getMediaUrlOnSuported : '';
    const relationUrl = isMedia
      ? mediaUrl
      : featuredImageUrl || getCtdFeaturedImage(relationType);

    const relationTypeToShow = isMedia ? 'Media' : relationType;

    const hasVariants =
      isMedia && isImage && imagePreviewSupported && onVariantCreate;

    const onClickCallback = useCallback(() => {
      onClick?.(relation, relationType);
    }, [onClick, relationType, relation]);

    const cardThumbnail = useMemo(() => {
      if (thumbnail) return thumbnail;

      return isMedia &&
        (relationsAreLoading ||
          (isImage && !imagePreviewSupported) ||
          !isImage) ? (
        <div className="flex items-center justify-center h-full w-full">
          <div
            className={twMerge(
              'flex items-center justify-center rounded-full bg-slate-700 h-12 w-12',
              relationsAreLoading ? 'animate-pulse' : 'bg-blue-300',
            )}
          >
            {relationsAreLoading ? '' : relation.extension || t('Global.NA')}
          </div>
        </div>
      ) : (
        ''
      );
    }, [
      thumbnail,
      isMedia,
      relationsAreLoading,
      isImage,
      relation.extension,
      imagePreviewSupported,
      t,
    ]);

    const fullSizeImage = useMemo(() => {
      return (
        isMedia &&
        !relationsAreLoading &&
        onShow &&
        relation.id &&
        relation.extension && (
          <div className="hidden group-hover:flex absolute top-2 right-2">
            <Button
              buttonColor="gray"
              buttonSize="xs"
              iconColor="blue"
              testId={'open-full-size-media'}
              iconImage={<ArrowUpRightIcon className="w-3" />}
              additionalClasses="shrink-0"
              onClick={(event) => {
                event.stopPropagation();
                onShow(relation);
              }}
            />
          </div>
        )
      );
    }, [isMedia, onShow, relation, relationsAreLoading]);

    return (
      <div
        className={twMerge(
          'rounded-lg w-full',
          hasVariants && 'border border-slate-400 dark:border-slate-700',
        )}
      >
        <Card
          ref={ref}
          onClick={onClick ? onClickCallback : null}
          body={
            relationsAreLoading ? (
              <div
                className="animate-pulse h-full flex items-center"
                {...getTestProps(testId, 'card-loading')}
              >
                <div className="grid grid-rows-2 gap-2 w-full">
                  <div className="h-3.5 my-[3.5px] w-3/4 bg-slate-400 rounded" />
                  <div className="h-3.5 my-[3.5px] w-1/2 bg-slate-500 rounded" />
                </div>
              </div>
            ) : (
              <div
                className="grid grid-rows-[fit-content(20px)_1fr] xs:grid-rows-1 xs:grid-cols-[1fr_fit-content(120px)] 
                          gap-2 h-full"
              >
                <div className="self-center space-y-2 min-w-0 max-w-full">
                  {imageTitleField && (
                    <div
                      className="truncate dark:text-white font-bold"
                      title={imageTitleField}
                    >
                      <CopyToClipboard
                        className="cursor-copy"
                        text={imageTitleField}
                        onCopy={() => {
                          toast.success(
                            t('ObjectsOfType.Copied', {
                              text: imageTitleField,
                            }),
                          );
                        }}
                        {...getTestProps(
                          testId,
                          `copy-image-title-${relation.id}`,
                        )}
                      >
                        <span onClick={(event) => event.stopPropagation()}>
                          {imageTitleField}
                        </span>
                      </CopyToClipboard>
                    </div>
                  )}
                  <div className="truncate dark:text-white" title={title}>
                    <CopyToClipboard
                      className="cursor-copy"
                      text={title}
                      onCopy={() => {
                        toast.success(
                          t('ObjectsOfType.Copied', { text: title }),
                        );
                      }}
                      {...getTestProps(testId, `copy-title-${relation.id}`)}
                    >
                      <span onClick={(event) => event.stopPropagation()}>
                        {title}
                      </span>
                    </CopyToClipboard>
                  </div>
                  <div className="truncate text-sm text-slate-400 pr-5">
                    {relationTypeToShow}
                  </div>
                </div>
                <div
                  className="order-first xs:order-none flex xs:flex-col items-center gap-1 xs:gap-2 xs:my-1"
                  onClick={(event) => event.stopPropagation()}
                >
                  <div className="flex gap-1 xs:gap-2">
                    {onEdit && (
                      <Button
                        iconImage={
                          <PencilIcon className="text-gray-700 dark:text-gray-50 opacity-50 hover:opacity-100 w-3.5" />
                        }
                        buttonColor="borderless"
                        onClick={onEdit}
                        additionalClasses="w-fit"
                        {...getTestProps(
                          testId,
                          `edit-${relationType}-${relation.id}`,
                          'testId',
                        )}
                        noPaddings
                      />
                    )}
                    {dragElement}
                    {onUp && relationsLength > 1 && (
                      <Button
                        onClick={onUp}
                        iconImage={
                          <ArrowUpThinIcon
                            className="h-3.5 text-gray-700 dark:text-gray-50 opacity-50
                        hover:opacity-100"
                          />
                        }
                        buttonColor="borderless"
                        additionalClasses="w-fit"
                        disabled={disabled || idx === 0}
                        {...getTestProps(
                          testId,
                          `up-${relationType}-${relation.id}`,
                          'testId',
                        )}
                        noPaddings
                      />
                    )}
                    {onDown && relationsLength > 1 && (
                      <Button
                        iconImage={
                          <ArrowUpThinIcon
                            className="rotate-180 h-3.5 text-gray-700 dark:text-gray-50 opacity-50
                        hover:opacity-100"
                          />
                        }
                        buttonColor="borderless"
                        additionalClasses="w-fit"
                        onClick={onDown}
                        disabled={disabled || idx === relationsLength - 1}
                        {...getTestProps(
                          testId,
                          `down-${relationType}-${relation.id}`,
                          'testId',
                        )}
                        noPaddings
                      />
                    )}
                    {onDelete && (
                      <Button
                        iconImage={
                          <DeleteIcon className="h-3.5 text-gray-700 dark:text-red-300 opacity-50 hover:opacity-100" />
                        }
                        buttonColor="borderless"
                        additionalClasses="w-fit"
                        onClick={onDelete}
                        disabled={disabled}
                        {...getTestProps(
                          testId,
                          `delete-${relationType}-${relation.id}`,
                          'testId',
                        )}
                        noPaddings
                      />
                    )}
                  </div>
                  <Tooltip
                    tooltip={t('ObjectsOfType.CopyIdTooltip')}
                    tooltipPlacement="leftCenter"
                    phoneTooltipPlacement="leftCenter"
                    additionalClasses="ml-auto xs:mt-auto"
                  >
                    <CopyToClipboard
                      text={relation.id}
                      onCopy={() =>
                        toast.success(
                          t('ObjectsOfType.Copied', { text: relation.id }),
                        )
                      }
                      {...getTestProps(testId, 'copy-id')}
                    >
                      <LinkIcon className="w-4 h-4 cursor-copy ml-1 text-sm text-gray-700 dark:text-blue" />
                    </CopyToClipboard>
                  </Tooltip>
                </div>
              </div>
            )
          }
          imageUrl={relationsAreLoading ? '' : relationUrl}
          imageUrlLoading={isMedia ? false : featuredImageLoading}
          thumbnail={cardThumbnail}
          imageOverlay={imageOverlay ? imageOverlay : fullSizeImage}
          additionalContainerClasses={twMerge(
            'bg-gray dark:bg-slate-800 w-full xs:h-24 flex',
            imageTitleField ? 'h-32' : 'h-24',
            hasVariants && 'rounded-b-none',
            additionalContainerClasses,
          )}
          additionalImageClasses={twMerge(isMedia ? 'w-fit h-fit' : 'w-full')}
          additionalImageContainerClasses="w-1/3 max-w-[150px] dark:bg-gray-700"
          additionalBodyClasses="px-2 xs:px-4 py-2 w-2/3 grow h-full"
          horizontal
          {...getTestProps(testId, '', 'testId')}
        />
        {hasVariants && (
          <VariantsGrid
            relation={relation}
            onVariantCreate={onVariantCreate}
            onVariantDelete={onVariantDelete}
            testId={testId}
          />
        )}
      </div>
    );
  },
);

export default RelationCard;

RelationCard.propTypes = {
  /**
   * Relation object
   */
  relation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Relation object type
   */
  relationType: PropTypes.string,
  /**
   * Relation type field used to get object title
   */
  titleField: PropTypes.string,
  /**
   * Content type featured image url
   */
  featuredImageUrl: PropTypes.string,
  /**
   * If featured image is loading
   */
  featuredImageLoading: PropTypes.bool,
  /**
   * If relations are loading
   */
  relationsAreLoading: PropTypes.bool,
  /**
   * On relation card click callback
   */
  onClick: PropTypes.func,
  /**
   * Relation card image overlay
   */
  imageOverlay: PropTypes.node,
  /**
   * Relation card image container
   */
  thumbnail: PropTypes.node,
  /**
   * All relations length
   */
  relationsLength: PropTypes.number,
  /**
   * If relation actions should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Index of relation in relations array
   */
  idx: PropTypes.number,
  /**
   * On variant create callback
   */
  onVariantCreate: PropTypes.func,
  /**
   * On variant delete callback
   */
  onVariantDelete: PropTypes.func,
  /**
   * Element to drag the card
   */
  dragElement: PropTypes.node,
  /**
   * Additional grid classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Relations card test id
   */
  testId: PropTypes.string,
};

RelationCard.defaultProps = {
  relation: {},
  relationType: '',
  titleField: '',
  relationsAreLoading: false,
  imageOverlay: '',
  thumbnail: '',
  relationsLength: 1,
  disabled: false,
  idx: 0,
  featuredImageUrl: '',
  featuredImageLoading: false,
  additionalContainerClasses: '',
  testId: '',
};
