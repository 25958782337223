import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useToken from '../../../hooks/useToken';
import useSelectedSpace from '../../../hooks/useSelectedSpace';
import useLocalStorageState from 'use-local-storage-state';
import { useApiKeys, useContentObject } from '../../../hooks/api';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import { generateForm } from '../../../lib/flotiq-client/external-services-requests';
import {
  checkResponseStatus,
  ResponseError,
} from '../../../lib/flotiq-client/response-errors';
import { postApiKey, postContentObject } from '../../../lib/flotiq-client';
import toast from 'react-hot-toast';
import FormGeneratorModalForm from '../../../form/FormGeneratorModalForm/FormGeneratorModalForm';
import Loader from '../../../components/Loader/Loader';
import { getTestProps } from '../../../lib/helpers';

const API_KEYS_PARAMS = {
  limit: 1000,
};

const parseAdvancedOptions = (options) =>
  Object.entries(options || {}).forEach(([key, value]) => {
    if ((Array.isArray(value) && !value.length) || !value) {
      delete options[key];
    }
  });

const FormGeneratorModal = ({ contentType, testId }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [user] = useLocalStorageState('cms.user');

  const jwt = useToken();
  const { space } = useSelectedSpace();

  const userId = user?.data?.id;

  const generatedFormId = `_generated_forms-${userId}-${contentType.name}`;

  const {
    entity: generatedForm,
    isLoading,
    errors,
    updateEntity: updateGeneratedForm,
    reload,
  } = useContentObject('_generated_forms', generatedFormId);

  const {
    data: apiKeys,
    isLoading: apiKeysLoading,
    errors: apiKeysErrors,
    reload: reloadApiKeys,
  } = useApiKeys(API_KEYS_PARAMS);

  useApiErrorsToast(apiKeysErrors);
  useApiErrorsToast(errors?.status === 404 ? null : errors);

  const onSubmit = useCallback(
    async (values) => {
      parseAdvancedOptions(values.config);
      parseAdvancedOptions(values.messages);

      const formId = generatedForm
        ? generatedForm.formUrl.split('/').pop()
        : undefined;
      const formData = {
        ...values,
        CTDslug: contentType.name,
        userIdentifier: userId,
      };

      try {
        const { body: formBody, status: formStatus } = await generateForm(jwt, {
          ...formData,
          ...(formId ? { formId } : {}),
        });
        checkResponseStatus(formBody, formStatus);

        let generatedFormResponse;
        const generatedFormValues = {
          ...formData,
          messages: JSON.stringify(formData.messages),
          config: JSON.stringify(formData.config),
          formUrl: formBody.formUrl,
          contentTypeName: '_generated_forms',
          id: generatedFormId,
        };

        if (generatedForm) {
          generatedFormResponse = await updateGeneratedForm(
            generatedFormValues,
          );
        } else {
          generatedFormResponse = await postContentObject(
            jwt,
            space,
            generatedFormValues,
          );
          reload();
        }

        checkResponseStatus(
          generatedFormResponse.body,
          generatedFormResponse.status,
        );

        return [generatedFormResponse.body, null];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [values, null];
        }
        toast.error(
          error.message || t('ContentTypeForm.FormGenerator.SaveError'),
        );
        return [values, error.errors];
      }
    },
    [
      contentType.name,
      generatedForm,
      generatedFormId,
      jwt,
      reload,
      space,
      t,
      updateGeneratedForm,
      userId,
    ],
  );

  const generateApiKey = useCallback(async () => {
    const newKey = {
      name: `${contentType.name} stand-alone form key`,
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
      permissions: [
        {
          canCreate: true,
          canRead: true,
          canUpdate: false,
          canDelete: false,
          contentTypeDefinition: { id: contentType.id },
        },
      ],
    };

    try {
      const { body, status } = await postApiKey(jwt, space, newKey);
      checkResponseStatus(body, status);

      queryClient.invalidateQueries({
        queryKey: ['api-keys'],
        refetchType: 'none',
      });
      toast.success(t('ApiKeys.SaveNewApiKeySuccess', { name: body.name }));
      await reloadApiKeys();

      return body;
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
        return null;
      }
      toast.error(t('ApiKeys.UpdateError'));
      return null;
    }
  }, [
    contentType.id,
    contentType.name,
    jwt,
    queryClient,
    reloadApiKeys,
    space,
    t,
  ]);

  return isLoading || apiKeysLoading ? (
    <div className="min-h-80 flex items-center justify-center">
      <Loader
        type="spinner-grid"
        {...getTestProps(testId, 'loader', 'testId')}
      />
    </div>
  ) : (
    <FormGeneratorModalForm
      contentType={contentType}
      onSubmit={onSubmit}
      generateApiKey={generateApiKey}
      generatedForm={generatedForm}
      apiKeys={apiKeys}
      testId={testId}
    />
  );
};

export default FormGeneratorModal;

FormGeneratorModal.propTypes = {
  /**
   * Content type for which form is displayed
   */
  contentType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

FormGeneratorModal.defaultProps = {
  testId: '',
};
