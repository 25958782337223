import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useContentObjectVersion } from '../../../hooks/api';
import Loader from '../../Loader/Loader';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import { useParams } from 'react-router-dom';
import { useCallback, useContext, useReducer, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import VersionPill from '../VersionPill/VersionPill';
import moment from 'moment';
import CancelButton from '../../Button/predefined/CancelButton/CancelButton';
import { ModalInstanceContext } from '../../../contexts/ModalContext';
import Button from '../../Button/Button';
import { ReloadIcon } from '../../../images/shapes';
import VersionContentObjectForm from './VersionContentObjectForm';
import { getTestProps } from '../../../lib/helpers';

const TabButton = ({ selected, onClick, isCurrent }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick(!isCurrent);
  }, [isCurrent, onClick]);

  return (
    <button
      className={twMerge(
        'font-semibold uppercase outline-0',
        'mx-1 px-1 py-2 md:mx-2 md:px-7 md:py-4',
        'text-sm md:text-base whitespace-nowrap',
        'border-b-[3px] border-transparent -mb-0.5 dark:text-gray-200',
        selected && 'border-blue text-blue dark:text-blue',
      )}
      onClick={handleClick}
    >
      {isCurrent ? t('Versions.CurrentVersion') : t('Versions.OldVersion')}
    </button>
  );
};

const VersionDiffModalContent = ({
  selectedVersion,
  latestVersion,
  currentChanges,
  contentType,
  testId,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const oldCtoFormikRef = useRef();
  const modalInstance = useContext(ModalInstanceContext);

  const [isCurrentTabSelected, toggleTab] = useReducer(
    (isCurrent) => !isCurrent,
    false,
  );

  const closeModal = useCallback(() => {
    modalInstance.resolve();
  }, [modalInstance]);

  const {
    entity: ctoVersion,
    errors: contentVersionErrors,
    isLoading: ctoVersionLoading,
  } = useContentObjectVersion(id, selectedVersion);

  useApiErrorsToast(contentVersionErrors);

  const restoreVersion = useCallback(async () => {
    if (!oldCtoFormikRef.current) return;
    const [newObject] = await oldCtoFormikRef.current.submitForm();
    modalInstance.resolve(newObject);
  }, [modalInstance]);

  return (
    <>
      <div
        className={`fixed w-full z-10 top-0 left-0 py-1 md:py-3 pl-2 pr-6 md:pl-11 md:pr-14 
          bg-white dark:bg-gray-900 text-base md:border-b border-neutral-500 dark:border-slate-300
          grid grid-cols-1 md:grid-cols-3 justify-center items-center`}
      >
        <div className="hidden md:flex items-center">
          <VersionPill status={selectedVersion.internal.status} />
          <span className="ml-1 md:ml-4">
            {moment(selectedVersion.internal.updatedAt).format(
              `L | LT | [${
                !selectedVersion.editor
                  ? 'N/A'
                  : selectedVersion.editor?.firstName +
                    ' ' +
                    selectedVersion.editor?.lastName
              }]`,
            )}
          </span>
        </div>
        <h2 className="mx-auto">{t('Versions.VersionDiff')}</h2>
        <div className="hidden md:flex ml-auto">
          {!latestVersion ? (
            t('Versions.CurrentChanges')
          ) : (
            <>
              <span className="mr-4">{t('Versions.CurrentVersion')}</span>
              <VersionPill status={latestVersion.internal?.status} />
            </>
          )}
        </div>

        <div
          className={`md:hidden w-full border-b dark:border-slate-800 
            grid grid-cols-2 justify-center scrollbar-sm scrollbar-hidden`}
        >
          <TabButton selected={!isCurrentTabSelected} onClick={toggleTab} />
          <TabButton
            selected={isCurrentTabSelected}
            onClick={toggleTab}
            isCurrent
          />
        </div>
      </div>

      {ctoVersionLoading ? (
        <div className="w-full h-64 flex items-center justify-center mt-20">
          <Loader size="normal" type="spinner-grid" />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 xl:gap-x-16 gap-y-4 mt-20">
          <VersionContentObjectForm
            oldContentObject={ctoVersion}
            currentContentObject={currentChanges}
            contentType={contentType}
            selected={!isCurrentTabSelected}
            formikRef={oldCtoFormikRef}
          />
          <VersionContentObjectForm
            oldContentObject={ctoVersion}
            currentContentObject={currentChanges}
            contentType={contentType}
            selected={isCurrentTabSelected}
            isCurrent
          />
        </div>
      )}

      <div
        className={twMerge(
          'w-full fixed left-0 bottom-0 flex items-center justify-center p-3 md:px-10 space-x-5',
          'border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900',
        )}
      >
        <CancelButton onClick={closeModal} />
        <Button
          buttonSize="sm"
          iconImage={<ReloadIcon className="w-3 min-w-3" />}
          iconPosition="start"
          onClick={restoreVersion}
          disabled={!ctoVersion}
          {...getTestProps(testId, 'restore', 'testId')}
        >
          {t('Versions.RestoreVersion')}
        </Button>
      </div>
    </>
  );
};

export default VersionDiffModalContent;

VersionDiffModalContent.propTypes = {
  /**
   * Selected version to compare
   */
  selectedVersion: PropTypes.object.isRequired,
  /**
   * Current form changes
   */
  currentChanges: PropTypes.object.isRequired,
  /**
   * Content type for object
   */
  contentType: PropTypes.object.isRequired,
  /**
   * Latest version information
   */
  latestVersion: PropTypes.object,
  /**
   * Test id for information container
   */
  testId: PropTypes.string,
};

VersionDiffModalContent.defaultProps = {
  testId: '',
};
