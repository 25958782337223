import { useCallback, useEffect, useMemo, useState } from 'react';
import { Switch as Toggle } from '@headlessui/react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';

const Switch = ({
  name,
  checked,
  onChange,
  onBlur,
  onFocus,
  error,
  helpText,
  disabled,
  readonly,
  required,
  side,
  size,
  theme,
  label,
  labelUp,
  passiveLabel,
  additionalClasses,
  additionalSwitchErrorClasses,
  testId,
}) => {
  const [currentChecked, setCurrentChecked] = useState(checked);

  useEffect(() => setCurrentChecked(checked), [checked]);

  const handleChange = useCallback(
    async (value) => {
      setCurrentChecked(value);
      const result = await onChange({ target: { value, name } }, value);

      if (result != null) {
        setCurrentChecked(result);
      }
    },
    [onChange, name],
  );

  const handleBlur = useCallback(() => {
    onBlur({ target: { name } });
  }, [onBlur, name]);

  const handleFocus = useCallback(() => {
    onFocus?.({ target: { name } });
  }, [onFocus, name]);

  const colorClasses = {
    dark: currentChecked ? 'bg-blue' : 'bg-white dark:bg-gray-700',
    light: 'slate-50',
  };

  const borderClasses = {
    dark:
      currentChecked && !disabled
        ? ''
        : 'border border-slate-200 dark:border-slate-800',
    light:
      currentChecked && !disabled
        ? 'border border-slate-200'
        : 'border dark:border-slate-800',
  };

  const markColorClasses = {
    dark: currentChecked ? 'bg-white' : 'bg-slate-400 dark:bg-gray-900',
    light: currentChecked
      ? 'bg-blue-600 dark:bg-blue'
      : 'bg-blue-600 dark:bg-blue-600 opacity-20',
  };

  const markDisabledColorClasses = {
    dark: currentChecked ? 'bg-white' : 'bg-slate-400 dark:bg-gray-800',
    light: currentChecked
      ? 'bg-slate-400 dark:bg-gray-700'
      : 'bg-slate-400 opacity-30',
  };

  const sizeClasses = {
    base: 'h-6 w-12',
    large: 'h-8 w-14',
  };

  const markTranslate = {
    left: currentChecked ? 'justify-start' : 'justify-end',
    right: currentChecked ? 'justify-end' : 'justify-start',
  };

  const markPadding = {
    base: 'px-0.5',
    large: 'px-1',
  };

  const switchLabel = useMemo(() => {
    if (!label) return;
    return (
      <Toggle.Label
        className={twMerge(
          'dark:text-white',
          labelUp ? 'text-sm text-slate-400 dark:text-white mb-1' : 'ml-2',
          !passiveLabel && 'cursor-pointer',
          disabled && !passiveLabel && 'cursor-not-allowed',
        )}
        passive={passiveLabel}
        {...getTestProps(testId, 'label')}
      >
        {label}
        {required && <RequiredTemplate testId={testId} />}
      </Toggle.Label>
    );
  }, [passiveLabel, required, disabled, label, labelUp, testId]);

  return (
    <Toggle.Group>
      <div
        className={twMerge(
          'w-fit items-center justify-items-center',
          labelUp ? 'grid grid-rows-2' : 'flex',
          additionalClasses,
        )}
      >
        {labelUp && switchLabel}
        <Toggle
          name={name}
          checked={currentChecked}
          onChange={handleChange}
          onBlur={handleBlur}
          className={twMerge(
            'relative inline-flex items-center rounded-full',
            borderClasses[theme] || borderClasses.dark,
            sizeClasses[size] || sizeClasses.base,
            disabled
              ? 'bg-gray dark:bg-gray-700 cursor-not-allowed'
              : colorClasses[theme] || colorClasses.dark,
          )}
          disabled={disabled || readonly}
          readOnly={readonly}
          onFocus={handleFocus}
          {...getTestProps(testId, 'switch')}
        >
          <div
            className={twMerge(
              'flex h-full w-full items-center justify-end',
              markTranslate[side] || markTranslate.left,
              markPadding[size] || markPadding.base,
            )}
            {...getTestProps(testId, 'mark-container')}
          >
            <span
              className={twMerge(
                'pointer-events-none relative h-[80%] aspect-square inline-block rounded-full',
                'bg-white dark:bg-gray-900 shadow transform ring-0 transition ease-in-out duration-200',
                disabled
                  ? markDisabledColorClasses[theme] ||
                      markDisabledColorClasses.dark
                  : markColorClasses[theme] || markColorClasses.dark,
              )}
              {...getTestProps(testId, 'mark')}
            ></span>
          </div>
        </Toggle>
        {!labelUp && switchLabel}
      </div>
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={error}
        additionalErrorClasses={additionalSwitchErrorClasses}
        testId={testId}
      />
    </Toggle.Group>
  );
};

export default Switch;

Switch.propTypes = {
  /**
   * True of false switch
   */
  checked: PropTypes.bool,
  /**
   * On checked value change
   */
  onChange: PropTypes.func,
  /**
   * Switch on blur handler
   */
  onBlur: PropTypes.func,
  /**
   * Switch on focus handler
   */
  onFocus: PropTypes.func,
  /**
   * If switch is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If switch is readonly (non interactive but without disabled classes)
   */
  readonly: PropTypes.bool,
  /**
   * Switch text that will inform about error
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Help text under switch
   */
  helpText: PropTypes.node,
  /**
   * Site of switch true state
   */
  side: PropTypes.oneOf(['left', 'right']),
  /**
   * Site of switch true state
   */
  theme: PropTypes.oneOf(['dark', 'light']),
  /**
   * Site of switch true state
   */
  size: PropTypes.oneOf(['base', 'large']),
  /**
   * Label of the switch
   */
  label: PropTypes.node,
  /**
   * If label should be above switch
   */
  labelUp: PropTypes.bool,
  /**
   * If label is clickable
   */
  passiveLabel: PropTypes.bool,
  /**
   * Switch name
   */
  name: PropTypes.string,
  /**
   * Switch additional container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Switch additional error classes
   */
  additionalSwitchErrorClasses: PropTypes.string,
  /**
   * Switch test id
   */
  testId: PropTypes.string,
};

Switch.defaultProps = {
  checked: false,
  onChange: /* istanbul ignore next */ () => null,
  onBlur: /* istanbul ignore next */ () => null,
  error: '',
  helpText: '',
  side: 'right',
  theme: 'dark',
  size: 'base',
  disabled: false,
  readonly: false,
  label: '',
  labelUp: false,
  passiveLabel: false,
  name: '',
  testId: '',
  additionalSwitchErrorClasses: '',
  additionalClasses: '',
};
